import React, { Component } from "react";
import { history } from "../_helpers";
import { PrivateRoute } from "../_components";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { auth } from "../_services/authService";
import { HomePage } from "../Pages/Home";
import { AboutPage } from "../Pages/About";
import { PolicyPage } from "../Pages/Policy";
import { FaqPage } from "../Pages/Faq";
import { ProfilePage } from "../Pages/Profile";
import { ContactPage } from "../Pages/Contact";
import { LoginPage } from "../Pages/Login";
import { WelcomePage } from "../Pages/Welcome";
import { SettingListPage } from "../Pages/Settings";
import { LoginAttemptPage } from "../Pages/Ana/User/LoginAttempts";
import { VoicePage, VoicePositionPage } from "../Pages/Voice";
import {
  UserListPage,
  UserDetailPage,
  UserPracticePage,
  UserSubRefillList,
  UserContractRefillList
} from "../Pages/Ana/User";
import { GroupListPage } from "../Pages/Ana/Group";
import { AgencyListPage, AgencyDetailPage, AgencySubRefillList } from "../Pages/Ana/Agency";
import { QuotationListPage, QuotationDetailPage } from "../Pages/Quotation";
import { SubscriptionListPage, SubscriptionDetailPage } from "../Pages/Ana/Subscription";
import Logout from "../_components/logout";
import NotFound from "../Pages/NotFound/notFound";
import HeaderNav from "../HeaderNav/HeaderNav";
import Footer from "../Footer/Footer";
import CookieConsent from "react-cookie-consent";
import CacheBuster from '../CacheBuster';
import "react-toastify/dist/ReactToastify.css";

class App extends Component {
  state = {};

  componentDidMount() {
    const user = auth.getCurrentUser();
    this.setState({ user });
  }

  render() {
    //const { alert } = this.props;
    const { user } = this.state;
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }
          return (
            <Router history={history}>
              <div>
                <HeaderNav user={user} />
                <div className="content">
                  <Switch>
                    <Route path="/about" component={AboutPage} />
                    <Route path="/contactus" component={ContactPage} />
                    <Route path="/policy" component={PolicyPage} />
                    <Route path="/login" component={LoginPage} />
                    <PrivateRoute
                      path="/logout"
                      policy={auth.ARole}
                      component={Logout}
                    />
                    <PrivateRoute
                      path="/faq"
                      policy={auth.ARole}
                      component={FaqPage}
                    />
                    <PrivateRoute
                      path="/profile"
                      policy={auth.ARole}
                      render={props => (
                        <ProfilePage {...props} user={this.state.user} />
                      )}
                    />
                    <PrivateRoute
                      path="/welcome"
                      render={props => (
                        <WelcomePage {...props} user={this.state.user} />
                      )}
                    />
                    <PrivateRoute
                      path="/settings"
                      render={props => (
                        <SettingListPage {...props} user={this.state.user} />
                      )}
                    />
                    <PrivateRoute
                      path="/voice"
                      policy={auth.SuperRole}
                      render={props => (
                        <VoicePage {...props} user={this.state.user} />
                      )}
                    />
                    <PrivateRoute
                      path="/voiceposition"
                      policy={auth.SuperRole}
                      render={props => (
                        <VoicePositionPage {...props} user={this.state.user} />
                      )}
                    />
                    <PrivateRoute
                      path="/loginattempts"
                      policy={auth.HighRole}
                      component={LoginAttemptPage}
                    />
                    <PrivateRoute
                      path="/users/practice/:id"
                      policy={auth.HighRole}
                      component={UserPracticePage}
                    />
                    <PrivateRoute
                      path="/users/:id"
                      policy={auth.HighRole}
                      component={UserDetailPage}
                    />
                    <PrivateRoute
                      path="/users"
                      policy={auth.HighRole}
                      component={UserListPage}
                    />
                    <PrivateRoute
                      path="/groups"
                      policy={auth.HighRole}
                      component={GroupListPage}
                    />
                    <PrivateRoute
                      path="/agencies/:id"
                      policy={auth.HighRole}
                      component={AgencyDetailPage}
                    />
                    <PrivateRoute
                      path="/agencies"
                      policy={auth.HighRole}
                      component={AgencyListPage}
                    />
                    <PrivateRoute
                      path="/subscriptions/:id"
                      policy={auth.HighRole}
                      component={SubscriptionDetailPage}
                    />
                    <PrivateRoute
                      path="/subscriptions"
                      policy={auth.HighRole}
                      component={SubscriptionListPage}
                    />
                    <PrivateRoute
                      path="/quotations/:id"
                      policy={auth.ARole}
                      render={props => (
                        <QuotationDetailPage {...props} user={this.state.user} />
                      )}
                    />
                    <PrivateRoute
                      path="/quotations"
                      policy={auth.ARole}
                      render={props => (
                        <QuotationListPage {...props} user={this.state.user} />
                      )}
                    />
                    <PrivateRoute
                      path="/subrefillagency/:id"
                      policy={auth.HighRole}
                      component={AgencySubRefillList}
                    />
                    <PrivateRoute
                      path="/subrefilluser/:id"
                      policy={auth.HighRole}
                      component={UserSubRefillList}
                    />
                    <PrivateRoute
                      path="/contractrefilluser/:id"
                      policy={auth.HighRole}
                      component={UserContractRefillList}
                    />
                    <Route path="/not-found" component={NotFound} />
                    <Route exact path="/" component={HomePage} />
                    <Redirect to="/not-found" />
                  </Switch>
                </div>
                <Footer />
                <CookieConsent
                  location="bottom"
                  buttonText="Acconsento"
                  declineButtonText="Non acconsento"
                  cookieName="myAwesomeCookieName2"
                  expires={365}
                  enableDeclineButton
                  onDecline={() => {
                    window.location.replace("https://www.garanteprivacy.it");
                  }}
                >
                  Questo sito usa i cookies per migliorare l'esperienza utente. Leggi
                  meglio la nostra <a href="/policy">Privacy Policy</a>.
                </CookieConsent>
              </div>
            </Router>
          );
        }}
      </CacheBuster>
    );
  }
}

export { App };
