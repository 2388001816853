import React from "react";
import { Link } from "react-router-dom";
import ContractRefillListTable from "../../../_components/ContractRefillListTable";
import Pagination from "../../../_components/common/pagination";
import SearchBox from "../../../_components/common/searchBox";
import Form from "../../../_components/common/form";
import { paginate } from "../../../utils/paginate";
import { cicassService, auth } from "../../../_services";
import { config, history } from "../../../_helpers";
import { ToastContainer, toast } from "react-toastify";
import spinner from "../../../images/form-preloader.gif";
import { standardMessage } from "../../../_constants";
import _ from "lodash";

const apiUrl = "ana_contract_refill/user";

class UserContractRefillList extends Form {
  state = {
    myData: [], //cicassService.getAll(apiUrl)
    choices: [],
    pageSize: config.pageSize,
    currentPage: 1,
    searchQuery: "",
    selectedChoice: null,
    sortColumn: { path: "title", order: "asc" },
    pagina: "Storico Refill Contratti: ",
    userData: {}
  };

  async componentDidMount() {
    const isExpired = auth.checkExpired();
    const dataId = this.props.match.params.id;

    //this.setState({ data: this.mapToViewModel(data) });
    if (Number(dataId) === -1) return;

    const { data: myData } = await cicassService.getById(apiUrl, dataId);
    const { data: userData } = await cicassService.getById("v_user_page", dataId);
    const choices = [{ id: "", name: "Refill" }];
    const gruppo = myData.map(myData => {
      choices.push({ id: myData.name, name: myData.name });
    });
    this.setState({ myData, choices, userData });
  }

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleChoiceSelect = choice => {
    this.setState({ selectedChoice: choice, searchQuery: "", currentPage: 1 });
  };

  handleSearch = query => {
    this.setState({ searchQuery: query, selectedChoice: null, currentPage: 1 });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const { pageSize, currentPage, sortColumn, selectedChoice, searchQuery, myData: allData } = this.state;

    let filtered = allData.filter(m => m.name !== "Cicass");
    if (searchQuery)
      filtered = allData.filter(m => m.name.toLowerCase().includes(searchQuery.toLowerCase()));
    else if (selectedChoice && selectedChoice.id)
      filtered = allData.filter(m => m.name === selectedChoice.id);
    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    const myData = paginate(sorted, currentPage, pageSize);
    return { totalCount: filtered.length, data: myData };
  };

  render() {
    const { length: count } = this.state.myData;
    const { pageSize, currentPage, sortColumn, pagina, searchQuery, userData } = this.state;
    const dataId = this.props.match.params.id;
    const backLink = "/users/" + dataId;
    const { totalCount, data: myData } = this.getPagedData();

    return (
      <div className="container-fluid">
        <ToastContainer autoClose={config.autoCloseToast} />
        <div className="row m-4">
          <div className="col"><h3>{pagina}{userData.name + " " + userData.surname}</h3></div>
          <div className="float-right">
            <Link className="btn btn-outline-dark" to={backLink}>
              {standardMessage.CMD_BACK}
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {totalCount === 0 && (
              <p>
                Non sono stati trovati refill con queste condizioni di ricerca.
              </p>
            )}
            {totalCount === 1 && <p>E' stato trovato un solo refill.</p>}
            {totalCount > 1 && <p>Sono stati trovati {totalCount} refill per questo utente.</p>}
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
            <ContractRefillListTable
              myData={myData}
              sortColumn={sortColumn}
              onDelete={this.handleDelete}
              onEdit={this.handleEdit}
              onSort={this.handleSort}
            />
            <Pagination
              key={totalCount}
              itemsCount={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

export { UserContractRefillList };
