import React from "react";
import { cicassService } from "../_services";
import _ from "lodash";

const apiUrl = "v_stat_count";

class Statistics extends React.Component {
  state = {
    myData: [] //cicassService.getAll(apiUrl)
  };

  async componentDidMount() {
    const { data: myData } = await cicassService.getAll(apiUrl);
    //console.log(myData);
    // pass a function to map
    //const map1 = myData.map(x => x.affiliates);

    //console.log(map1);
    this.setState({ myData });
  }

  handleDate(last) {
    if (last[0] != null)
      return new Date(last[0].toLocaleString("it-IT")).toLocaleDateString();
    else
      return "gg/mm/aaaa";
  }

  render() {
    const { length: count } = this.state.myData;
    // const { stat } = this.state.myData;
    if (count === 0) return <h2>non ci sono statistiche</h2>;

    return (
      <React.Fragment>
        <div className="row m-4 breadcrumb">
          <div className="col">
            Affiliati: {this.state.myData.map(x => x.affiliates)}
          </div>
          <div className="col">
            Prospetti spesa: {this.state.myData.map(x => x.quotations)}
          </div>
          <div className="col">
            Ultimo prospetto spesa: {this.handleDate(this.state.myData.map(x => x.lastquotation))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Statistics;
