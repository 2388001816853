import React, { Component } from "react";
import Table from "./common/table";
import { Link } from "react-router-dom";
import { standardMessage } from "../_constants/";

class AgencylistTable extends Component {
  columns = [
    { path: "name", label: "Nome" },
    { path: "city", label: "Città", invisible: true },
    { path: "piva", label: "Partita IVA" },
    { path: "reference", label: "Referente", invisible: true },
    {
      key: "edit",
      content: choice => (
        <button
          onClick={() => this.props.onEdit(choice)}
          className="btn btn-outline-dark"
        >
          <Link
            style={{ color: "inherit", textDecoration: "inherit" }}
            to={`/agencies/${choice.id}`}
          >
            {standardMessage.CMD_DETAILS}
          </Link>
        </button>
      ) //
    }
  ];

  render() {
    const { myData, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={myData}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default AgencylistTable;
