import React from "react";
import Milano from "../../images/milan-1826504_1920.jpg";
import Galleria from "../../images/galleria.jpg";
import Sede from "../../images/afa.jpg";

const linkStyle = {
  color: 'red',
  textDecoration: 'none'
};

var sectionStyle1 = {
  width: "100%",
  //  height: "800px",
  backgroundImage: `url(${Milano})`
};

var sectionStyle2 = {
  width: "100%",
  //  height: "800px",
  backgroundImage: `url(${Sede})`
};

var sectionStyle3 = {
  width: "100%",
  //  height: "800px",
  backgroundImage: `url(${Galleria})`
};

class HomePage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <header>
          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-ride="carousel"
          >
            <ol className="carousel-indicators">
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="0"
                className="active"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="1"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="2"
              ></li>
            </ol>
            <div className="carousel-inner" role="listbox">
              <div className="carousel-item active" style={sectionStyle1}>
                <div className="carousel-caption d-none d-md-block">
                  <h3>AFA solving</h3>
                  <p>Al tuo servizio su Milano ed hinterland</p>
                </div>
              </div>
              <div className="carousel-item" style={sectionStyle2}>
                <div className="carousel-caption d-none d-md-block">
                  <h3>AFA solving</h3>
                  <p>Al tuo servizio su Milano ed hinterland</p>
                </div>
              </div>
              <div className="carousel-item" style={sectionStyle3}>
                <div className="carousel-caption d-none d-md-block">
                  <h3>AFA solving</h3>
                  <p>Al tuo servizio su Milano ed hinterland</p>
                </div>
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </header>

        <div className="container pt-3">
          <div className="row">
            <div className="col-lg-6">
              <h2>Chi Siamo</h2>
              <p>
                AFA solving nasce con l'obiettivo di semplificare e velocizzare
                i servizi essenziali di aziende e privati tenendosi sempre
                aggiornati sui repentini cambiamenti del nostro sistema.
              </p>
              <p>
                Ci dedichiamo al supporto del privato e aziende offrendo servizi
                di consulenza innovativi e personalizzati per la gestione di
                pratiche mobiliari immobiliari e finanziarie in base alle
                necessità oggettive e soggettive dello stesso.
              </p>

            </div>
            <div className="col-lg-6">
              <h2>I nostri Servizi</h2>
              <p>AFA solving fornisce ed eroga una serie di prestazioni quali:</p>
              <ul>
                <li>
                  elaborazione dati espletamento pratiche e visure presso enti
                  addetti
                </li>

                <li>
                  servizi di segretariato archiviazione e registrazione dati per
                  aziende
                </li>

                <li>
                  consulenza per operazioni mobiliari immobiliari e finanziarie
                </li>

                <li>
                  sicurezza del lavoro consulenza formazione e rilascio
                  licenze/autorizzazioni
                </li>
              </ul>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6"></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export { HomePage };
