import React from "react";
import { Link } from "react-router-dom";
import Joi from "joi-browser";
import Form from "../../_components/common/form";
import Modal from "../../_components/common/modal";
import { cicassService, auth } from "../../_services";
import { config, history } from "../../_helpers";
import { ToastContainer, toast } from "react-toastify";
import { standardMessage } from "../../_constants/";
import _ from "lodash";

const apiUrl = "v_user_page";
const apiUrlLogin = "ana_login";

class ProfilePage extends Form {
  constructor(props) {
    super(props);
  }

  state = {
    data: { id: -1, oldPassword: "", newPassword: "", newPasswordConfirm: "" },
    saveMessage: standardMessage.CMD_NEW,
    errors: {},
    loadingReset: false,
    modalQuestion: "",
    pagina: "Utente"
  };

  schema = {
    id: Joi.number(),
    oldPassword: Joi.string()
      .required()
      .min(1)
      .max(100)
      .label("Password"),
    newPassword: Joi.string()
      .required()
      .min(8)
      .max(100)
      .label("Password")
      .regex(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/, 'richiesto'),
    newPasswordConfirm: Joi.string()
      .required()
      .min(8)
      .max(100)
      .label("Password")
  };

  async componentDidMount() {
    const isExpired = auth.checkExpired();
  }

  handleModalMessage = idBtn => {
    if (this.state.newPassword === this.state.newPasswordConfirm) {
      switch (idBtn) {
        case "r": {
          this.setState({
            modalQuestion: standardMessage.MODAL_QUESTION_RESET_PASSWORD
          });
          break;
        }
        default:
          break;
      }
    }
  };

  handleModalConfirm = () => {
    switch (this.state.modalQuestion) {
      case standardMessage.MODAL_QUESTION_RESET_PASSWORD: {
        this.handleResetPassword();
        break;
      }
      default:
        break;
    }
  };

  handleResetPassword = async () => {
    this.setState({ loadingReset: true });
    this.setState({ disableButton: true });
    const dataToSave = {
      id: Number(this.props.user.name),
      password: this.state.data.password
    };
    try {
      /*await cicassService.getWithCustomUrl(
        apiUrlLogin +
        "/resetpassword/" +
        dataToSave.id +
        "/" +
        dataToSave.password
      );*/
      await auth.changePassword(this.state.data.oldPassword, this.state.data.newPassword);
      toast.success(standardMessage.CMD_CHANGE_PWD_OK);
      this.setState({ loadingReset: false });
      auth.logout();
      window.location = "/login";
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error(standardMessage.CMD_CHANGE_PWD_KO);
      this.setState({ loadingReset: false });
    }
    this.setState({ disableButton: false });
  };

  render() {
    const { pagina, loadingReset, modalQuestion } = this.state;
    const { user } = this.props;
    return (
      <React.Fragment>
        <div className="container-fluid">
          <ToastContainer autoClose={config.autoCloseToast} />
          <div className="row m-4">
            <div className="col">
              <h3>{pagina}</h3>
            </div>
            <div className="float-right">
              {this.renderButtonLink(standardMessage.CMD_BACK, "/welcome")}
            </div>
          </div>
          <div className="m-4">
            <div>
              <b>Nome</b>&nbsp;
              {user &&
                user.given_name.charAt(0).toUpperCase() +
                user.given_name.slice(1)}
            </div>
            <div>
              <b>Cognome</b>&nbsp;
              {user &&
                user.family_name.charAt(0).toUpperCase() +
                user.family_name.slice(1)}
              <br />
            </div>
            <div>
              <b>Gruppo</b>&nbsp;
              {user && user.role.charAt(0).toUpperCase() + user.role.slice(1)}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div>
                <h3>Modifica Password</h3>
                <p>
                  La nuova password deve avere il seguente formato:
                  <ul>
                    <li>essere composta da almeno 8 caratteri</li>
                    <li>avere almeno una lettera maiuscola (A..Z)</li>
                    <li>avere almeno una lettera minuscola (a..z)</li>
                    <li>avere almeno un numero (0..9)</li>
                    <li>avere almeno un carattere speciale (!.$%"?-+[]@)</li>
                  </ul>
                  Esempio di password corretta: <b>mIL4n0!</b><br />
                  Esempio di password errata: <b>milano</b>
                </p>
              </div>
              <form onSubmit={this.handleSubmit}>
                {this.renderInput("oldPassword", "Password Attuale", "Inserisci la password usata per accedere al sito", "text", false, false, "password")}
                {this.renderInput("newPassword", "Nuova Password", "Inserire la nuova Password", "text", false, false, "password")}
                {this.renderInput("newPasswordConfirm", "Conferma Password", "Reinserire la nuova Password", "text", false, false, "password")}

                {this.state.data.newPassword === this.state.data.newPasswordConfirm &&
                  this.state.data.newPassword &&
                  this.renderButtonModal(
                    standardMessage.CMD_CHANGE_PWD,
                    "modalOp",
                    true,
                    loadingReset,
                    false,
                    () => {
                      return this.handleModalMessage("r");
                    },
                    "btn btn-outline-warning m-4"
                  )}
              </form>
            </div>
          </div>
          <Modal
            modalId="modalOp"
            modalTitle={standardMessage.MODAL_TITLE_ATTENTION}
            modalBody={modalQuestion}
            onModalConfirm={this.handleModalConfirm}
          />
          <div className="row">
            <div className="col">
              <div>
                <h3>Informazioni sul Trattamento dei dati personali</h3>
                <p>Puoi leggere l'informativa <a href="./docs/iar.pdf" target="_blank">qui</a>.</p>
              </div>

            </div>
          </div>




        </div>
      </React.Fragment>
    );
  }
}

export { ProfilePage };
