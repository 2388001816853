import React, { Component } from "react";
import { standardMessage } from "../../_constants/";
import { Link } from "react-router-dom";
import Joi from "joi-browser";
class MacroPracticeTypeComponent extends Component {
  state = {
    editRow: { id: -1, ana_macro_practicetype_id: -1, ana_user_id: -1, name: '-', description: '-', min_value: 0, max_value: 0, breakeven_value: 0, disabled: true },
    componentData: {
      name: this.props.name,
      label: this.props.label,
      list: this.props.datalist
    },
    errors: {}
  }

  

  schema = {
    id: Joi.number(),
    ana_macro_practicetype_id: Joi.number(),
    ana_user_id: Joi.number(),
    name: Joi.string(),
    description: Joi.string(),
    breakeven_value: Joi.number(),
    disabled: Joi.boolean(),
    min_value: Joi.number()
      .required()
      .label("Valore Min."),
    max_value: Joi.number()
      .required()
      .label("Valore Max."),
  };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.editRow, this.schema, options);
    //console.log(error);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    //console.log(errors);
    return errors;
  };
  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleLocalCopy = itemSelected => {
    //console.log(itemSelected);
    this.setState({ editRow: this.copyRow(itemSelected) });
  };

  handleUpdate = myData => {
    //
    if (myData.disabled == false) {
      this.props.onEdit(myData);
      this.handleLocalCopy(null);
      //console.log(myData);
    }

  };

  handleChange = ({ target }) => {
    let localCopy = JSON.parse(JSON.stringify(this.state.editRow))
    const value = target.value;
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(target);
    if (errorMessage && !(target.value === "")) errors[target.name] = errorMessage;
    else {
      delete errors[target.name];
      localCopy[target.name] = target.value;
      this.setState({
        editRow: localCopy
      })
    }
  };


  copyRow(data) {
    if (data == null) return { id: -1, ana_macro_practicetype_id: - 1, ana_user_id: -1, name: '-', description: '-', min_value: 0, max_value: 0, breakeven_value: 0, disabled: true };
    return {
      id: data.id,
      ana_macro_practicetype_id: data.ana_macro_practicetype_id,
      ana_user_id: data.ana_user_id,
      name: data.name,
      description: data.description,
      min_value: data.min_value,
      max_value: data.max_value,
      breakeven_value: data.breakeven_value,
      disabled: false
    };
  }



  render() {
    const { name, label, list } = this.state.componentData;
    const { editRow, errors } = this.state;
    return (
      <div className="form-group m-4">
        <label htmlFor={name} className="font-weight-bold" >{label}</label>
        <table className="table mx-auto table-hover justify-content-center shadow p-3 mb-5 bg-white rounded">
          <thead>
            <tr>
              <th>Nome</th>
              <th className="d-none d-sm-table-cell">Descrizione</th>
              {this.props.extraScaglione==1 && <th title="0 - 149000 Euro">Extra Scaglione</th>}
              <th title="0 - 300000 Euro">Scaglione 1</th>
              <th title="300000 - 500000 Euro">Scaglione 2</th>
              <th title="500000 - 700000 Euro">Scaglione 3</th>
              <th title="700000 - 1000000 Euro">Scaglione 4</th>
              <th title="da 1000000 Euro in su">Scaglione 5</th>
              <th>Azioni</th>
            </tr>
          </thead>
          <tbody>
            {list && list.map((item, i) => (
              <tr key={i}>
                <td>{item.name}</td>
                <td className="d-none d-sm-table-cell">{item.description}</td>
                {this.props.extraScaglione==1 && <td>&euro; {item.max_value}</td>}
                <td>&euro; {item.min_value + item.stagger_00}</td>
                <td>&euro; {item.min_value + item.stagger_01}</td>
                <td>&euro; {item.min_value + item.stagger_02}</td>
                <td>&euro; {item.min_value + item.stagger_03}</td>
                <td>&euro; {item.min_value + item.stagger_04}</td>
                <td>
                  <Link className="btn btn-sm btn-outline-dark" onClick={() => this.handleLocalCopy(item)} to="#">{standardMessage.CMD_EDIT}</Link>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr><td colSpan="9"></td></tr>
            <tr>
              <td>{editRow.name}</td>
              <td className="d-none d-sm-table-cell">{editRow.description}</td>
              <td className="col-sm-2">
                <div className="input-group"><div className="input-group-addon">EUR</div>
                  <input type="text" name="min_value" placeholder="Valore Scaglione 1" className="form-control" disabled={editRow.disabled} value={editRow.min_value || ""} onChange={this.handleChange} />
                  {errors.min_value && <div className="alert alert-danger">{errors.min_value}</div>}
                </div>
              </td>
              <td></td>
              {this.props.extraScaglione==1 && <td></td>}
              <td></td>
              <td></td>
              <td></td>
              <td>
                <button className="btn btn-sm btn-outline-success mr-2 mb-2" disabled={editRow.disabled} onClick={() => this.handleUpdate(editRow)}>{standardMessage.CMD_CONFIRM}</button>
                <button className="btn btn-sm btn-outline-danger mr-2 mb-2" disabled={editRow.disabled} onClick={() => this.handleLocalCopy(null)}>{standardMessage.CMD_RESET}</button>
              </td>
            </tr>

            {this.props.extraScaglione==1 && 
              <tr style={{backgroundColor: '#FFFFD0'}}>
              <td>{editRow.name}</td>
              <td className="d-none d-sm-table-cell">{editRow.description}</td>
              <td className="col-sm-2">
                <div className="input-group"><div className="input-group-addon">EUR</div>
                  <input type="text" name="max_value" placeholder="Extra Scaglione" className="form-control" disabled={editRow.disabled} value={editRow.max_value || ""} onChange={this.handleChange} />
                  {errors.max_value && <div className="alert alert-danger">{errors.max_value}</div>}
                </div>
              </td>
              <td></td>
              {this.props.extraScaglione==1 && <td></td>}
              <td></td>
              <td></td>
              <td></td>
              <td>
                <button className="btn btn-sm btn-outline-success mr-2 mb-2" disabled={editRow.disabled} onClick={() => this.handleUpdate(editRow)}>{standardMessage.CMD_CONFIRM}</button>
                <button className="btn btn-sm btn-outline-danger mr-2 mb-2" disabled={editRow.disabled} onClick={() => this.handleLocalCopy(null)}>{standardMessage.CMD_RESET}</button>
              </td>
            </tr>
            }
          </tfoot>
        </table>
      </div>
    );
  }
}
export default MacroPracticeTypeComponent;
