import React from "react";

const SelectWithId = ({ name, label, options, error, ...rest }) => {
  return (
    <div className="form-group mr-2">
      {label != "none" && <label htmlFor={name} className="font-weight-bold" >{label}</label>}
      <select name={name} id={name} {...rest} className="form-control">
        {options.map(option => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default SelectWithId;
