import React from "react";

const Modal = ({
  modalId,
  modalTitle,
  modalBody,
  onModalConfirm,
  btnOk = "Conferma",
  btnClose = "Annulla"
}) => {
  return (
    <div
      className="modal fade"
      id={modalId}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {modalTitle}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">{modalBody}</div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-outline-danger"
              data-dismiss="modal"
            >
              {btnClose}
            </button>
            <button
              type="button"
              className="btn btn-outline-success"
              onClick={onModalConfirm}
              data-dismiss="modal"
            >
              {btnOk}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Modal;
