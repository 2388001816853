import React from "react";
import { Link } from "react-router-dom";

const Card = ({
  img,
  label,
  text,
  btnText,
  destination,
  target,
  ...rest
}) => {
  return (
    <React.Fragment>
      <div className="card-deck col-xl-3 col-lg-3 col-md-4 col-sm-4 col-xs-12 mb-3">
        <div className="card">
          <img src={img} className="card-img-top" alt="..." height="300" />
          <div className="card-body">
            <h5 className="card-title">{label}</h5>
            <p className="card-text">{text}</p>
            {destination && <Link className="btn btn-outline-primary" to={destination}>
              {btnText}
            </Link>}
            {target && <a href={target} className="btn btn-outline-primary" target="_blank">
              {btnText}
            </a>}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Card;
