import React, { Component } from "react";
import { Link } from "react-router-dom";
import Joi from "joi-browser";
import Form from "../../../_components/common/form";
import Modal from "../../../_components/common/modal";
import { cicassService, auth } from "../../../_services";
import { config, history } from "../../../_helpers";
import { ToastContainer, toast } from "react-toastify";
import { standardMessage } from "../../../_constants/";

const apiUrlPractice = "ana_practicetype";
const apiUrl = "user_practicetype";
class UserPracticePage extends Form {
  _isMounted = false;
  state = {
    data: {
      id: -1,
      practiceTypeId: []
    },
    errors: {},
    practicetype: [],
    disableButton: false,
    loadingSave: false,
    modalQuestion: "",
    selectAll: "Tutti"
  };
  schema = {
    id: Joi.number()
  };
  async componentDidMount() {
    this._isMounted = true;
    const isExpired = auth.checkExpired();
    const dataId = this.props.match.params.id;
    const practiceTypeId = [];
    const { data: practicetype } = await cicassService.getAll(apiUrlPractice);
    const { data: practicetypeDB } = await cicassService.getAll(apiUrl + "/byanauserid/" + dataId);

    if (this._isMounted) {
      this.setState({ practicetype });
      for (var i = 0; i < practicetype.length; i++) {
        practiceTypeId[practicetype[i].id] = false;
      }
      if (!(Number(dataId) === -1)) {
        for (var i = 0; i < practicetypeDB.length; i++) {
          practiceTypeId[practicetypeDB[i].ana_practicetype_id] = true;
        }
      }
      this.setState({ data: this.mapToViewModel(practiceTypeId) });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  doSubmit = async () => {
    //console.log(this.state.data.practiceTypeId);
    this.setState({ loadingSave: true });
    this.setState({ disableButton: true });
    // SALVO in ANA_LOGIN
    const arrayToSave = [];
    for (var i = 1; i < this.state.data.practiceTypeId.length; i++) {
      if (this.state.data.practiceTypeId[i] == true) {
        const dataToSave = {
          id: -1,
          ana_user_id: Number(this.props.match.params.id),
          ana_practicetype_id: Number(i)
        };
        arrayToSave.push(dataToSave);
      }
    }
    //console.log(arrayToSave);
    const dbAna = await cicassService.postArray(apiUrl + "/array/" + this.state.data.id, arrayToSave);
    toast.success(standardMessage.CMD_SAVE_OK);
    //this.props.history.push("/users");
    this.setState({ loadingSave: false });
    this.setState({ disableButton: false });
    window.location = "/users";
  };

  mapToViewModel(data) {
    // devo mappare i miei dati
    return {
      id: this.props.match.params.id,
      practiceTypeId: [...data]
    };
  }

  handleModalMessage = idBtn => {
    switch (idBtn) {
      case "d": {
        this.setState({ modalQuestion: standardMessage.MODAL_QUESTION_DELETE });
        break;
      }
      case "s": {
        this.setState({ modalQuestion: standardMessage.MODAL_QUESTION_SAVE });
        break;
      }
      default:
        break;
    }
  };

  handleModalConfirm = () => {
    this.doSubmit();
  };

  handleSelectAllLocal = (value) => {
    let {data, practicetype} = this.state;
    //console.log(data, practicetype);
    for (var i = 0; i < practicetype.length; i++) {
      data.practiceTypeId[practicetype[i].id] = value;
    }
    this.setState({ data });
  }
  render() {
    const { data, loadingSave, disableButton, modalQuestion,selectAll } = this.state;
    const dataId = this.props.match.params.id;

    const checkboxesToRender = this.state.practicetype.map((item, i) => (
      <div className="row" key={i}>
        <div className="col ml-4"> {this.renderInputCheckBoxArray( item.id,"practiceTypeId", item.description )} </div>
      </div>
    ));
    return (
      <div className="container-fluid">
        <ToastContainer autoClose={config.autoCloseToast} />
        <div className="row m-4">
          <div className="col"><h1>Utente - Pratiche</h1></div>
          <div className="float-right">{this.renderButtonLink(standardMessage.CMD_BACK, "/users/" + this.props.match.params.id)}</div>
        </div>
        <form onSubmit={this.handleSubmit}>
          
          {checkboxesToRender}
          {this.renderButtonModal(standardMessage.CMD_EDIT, "modalOp", false, loadingSave, disableButton, () => { return this.handleModalMessage("s"); }, "btn btn-outline-success ml-4 mb-4")}
          <button type="button" className="btn btn-outline-primary ml-4 mr-4 mb-4" onClick={() => this.handleSelectAllLocal(true)}  >Tutti  </button>
          <button type="button" className="btn btn-outline-secondary mb-4" onClick={() => this.handleSelectAllLocal(false)}  >Nessuno  </button>
        </form>
        <Modal
          modalId="modalOp"
          modalTitle={standardMessage.MODAL_TITLE_ATTENTION}
          modalBody={modalQuestion}
          onModalConfirm={this.handleModalConfirm}
        />
      </div>
    );
  }
}
export { UserPracticePage };
