import React from "react";
import Joi from "joi-browser";
import Form from "../../_components/common/form";
import ModalNew from "./modalnew";
import VoicepositionlistTable from "../../_components/VoicepositionlistTable";
import { cicassService, auth } from "../../_services";
import { config, history } from "../../_helpers";
import { ToastContainer, toast } from "react-toastify";
import { paginate } from "../../utils/paginate";
import Pagination from "../../_components/common/pagination";
import SearchBox from "../../_components/common/searchBox";
import { standardMessage } from "../../_constants";
//import Select from "../../_components/common/select";
import spinner from "../../images/form-preloader.gif";
import _ from "lodash";
const apiUrl = "v_voice";
const apiUrlAnaQuotationType = "ana_quotationtype";
const apiUrlvoice = "voice";
const apiUrlvoicepos = "voiceposition";

class VoicePositionPage extends Form {
  state = {
    data: {
      id: -1
    },
    id: -1,
    ana_quotationtype_id: -1,
    voice_id: -1,
    rowValue: -1,
    columnValue: -1,
    addRow: 0,
    ana_quotationtypes: [],
    voices: [],
    quotationtypes: [],
    errors: {},
    myData: [],
    pageSize: config.pageSize,
    currentPage: 1,
    searchQuery: "",
    selectedChoice: null,
    sortColumn: { path: "title", order: "asc" },
    pagina: "Posizioni delle Voci Excel",
    plusMessage: "+",
    modalQuestion: "",
  };

  schema = {
    //  id: Joi.number(),
    // ana_quotationtype_id: Joi.number(),
    //  voice_id: Joi.number(),
    //  rowValue: Joi.number(),
    //  columnValue: Joi.number(),
    //  addRow: Joi.number()
  };

  async componentDidMount() {
    const isExpired = auth.checkExpired();
    const { data: myData } = await cicassService.getAll(apiUrl);
    //console.log(myData);
    const choices2 = [{ id: "Tutti", name: "Tutti" }];
    const quottypes = myData.map(myData => {
      choices2.push({ id: myData.quotationtype, name: myData.quotationtype });
    });
    const choices = [];
    const map = new Map();
    for (const item of choices2) {
      if (!map.has(item.name)) {
        map.set(item.name, true); // set any value to Map
        choices.push({
          id: item.id,
          name: item.name
        });

      }
    }
    this.setState({ myData, quotationtypes: choices });

    const { data: ana_quotationtypes } = await cicassService.getAll(apiUrlAnaQuotationType);
    this.setState({ ana_quotationtypes });
    const { data: voices } = await cicassService.getAll(apiUrlvoice);
    this.setState({ voices });
    // console.log(ana_quotationtypes);
    //console.log("ciao");
  }


  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSearch = query => {
    this.setState({ searchQuery: query, selectedChoice: null, currentPage: 1 });
  };

  handleEdit = singleElem => {
    // console.log("Modifica", singleElem);
    //history.push(`/users/${singleElem.id}`);
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  handleChange = ({ target }) => {
    //console.log("cioane");
    switch (target.id) {
      case "quotationtype":
        this.setState({ selectedChoice: target.value });
        break;

    }

    //console.log(this.state.quotationtypes);
  };

  handleChangeLoc = ({ target }) => {
    var x = Number(target.value);
    switch (target.id) {
      case "ana_quotationtype_id":
        this.setState({ ana_quotationtype_id: x });
        break;
      case "voice_id":
        this.setState({ voice_id: x });
        break;
      case "rowValue":
        this.setState({ rowValue: x });
        break;
      case "columnValue":
        this.setState({ columnValue: x });
        break;
      case "addRow":
        this.setState({ addRow: x });
        break;
    }
  };


  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      selectedChoice,
      searchQuery,
      myData: allData
    } = this.state;
    // let filtered = allData.filter(m => m.name !== "SuperUser");
    let filtered = allData;
    if (selectedChoice) {
      filtered = filtered.filter(m => ((m.quotationtype === selectedChoice && selectedChoice != 'Tutti') || (m && selectedChoice === 'Tutti')));
    }
    if (searchQuery)
      filtered = allData.filter(m =>
        m.value.toLowerCase().includes(searchQuery.toLowerCase())
      );
    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    const myData = paginate(sorted, currentPage, pageSize);
    return { totalCount: filtered.length, data: myData };
  };



  handleModalMessage = idBtn => {
    switch (idBtn) {
      case "s": {
        this.setState({ modalQuestion: standardMessage.MODAL_QUESTION_CREATE });
        break;
      }
      default:
        break;
    }
  };

  handleModalConfirm = () => {
    switch (this.state.modalQuestion) {
      case standardMessage.MODAL_QUESTION_CREATE: {
        this.doSubmit();
        break;
      }
      default:
        break;
    }
  };

  doSubmit = async () => {
    //console.log("creo voce position");
    const dataToSave = {
      id: Number(this.state.data.id),
      ana_quotationtype_id: Number(this.state.ana_quotationtype_id),
      voice_id: Number(this.state.voice_id),
      rowValue: Number(this.state.rowValue),
      columnValue: Number(this.state.columnValue),
      addRow: Number(this.state.addRow)
    };
    //console.log(dataToSave);

    const dbAna = await cicassService.saveOp(apiUrlvoicepos, dataToSave);
    toast.success(standardMessage.CMD_SAVE_OK);

    //window.location = "/voiceposition";
  }


  render() {
    const { length: count } = this.state.myData;
    const {
      pageSize,
      currentPage,
      sortColumn,
      pagina,
      searchQuery,
      modalQuestion,
      loadingSave,
      plusMessage,
      disableButton,
      quotationtypes
    } = this.state;

    if (count === 0) return (<React.Fragment>
      <div className="container-fluid">
        <div className="text-center">
          <img className="img-fluid" src={spinner} alt="loading..." />
        </div>
      </div>
    </React.Fragment>)

    const { totalCount, data: myData } = this.getPagedData();

    return (
      <div>

        <React.Fragment>
          <div className="container-fluid">
            <ToastContainer autoClose={config.autoCloseToast} />
            <div className="row m-4">
              <div className="col">
                <h3>{pagina}</h3>
              </div>
              <div className="float-right">

              </div>
              <div className="float-right">
                {this.renderButtonLink(standardMessage.CMD_BACK, "/welcome")}
              </div>
            </div>
            <div className="row">
              <div className="d-none d-lg-block col-sm-2">
                <React.Fragment>{
                  this.renderSelect("quotationtype", "Tipo prospetto spesa", this.state.quotationtypes)
                }
                </React.Fragment>
              </div>
              <div className="col">
                <p>Ci sono {totalCount} voci definite.</p>
                <SearchBox value={searchQuery} onChange={this.handleSearch} />
                <VoicepositionlistTable
                  myData={myData}
                  sortColumn={sortColumn}
                  onDelete={this.handleDelete}
                  onEdit={this.handleEdit}
                  onSort={this.handleSort}
                />
                <Pagination
                  key={totalCount}
                  itemsCount={totalCount}
                  pageSize={pageSize}
                  currentPage={currentPage}
                  onPageChange={this.handlePageChange}
                />
              </div>
            </div>
            <div className="form-group mr-2">
              <div className="row m-2">
                <div className="row m-2">
                  {
                    <React.Fragment>
                      <label className="font-weight-bold" >Tipo</label>
                      <select name="ana_quotationtype_id" id="ana_quotationtype_id" className="form-control" onChange={this.handleChangeLoc}>
                        <option value="" />
                        {this.state.ana_quotationtypes.map(option => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </React.Fragment>
                  }
                </div>
                <div className="row m-2">
                  {
                    <React.Fragment>
                      <label className="font-weight-bold" >Voce</label>
                      <select name="voice_id" id="voice_id" className="form-control" onChange={this.handleChangeLoc}>
                        <option value="" />
                        {this.state.voices.map(option => (
                          <option key={option.id} value={option.id}>
                            {option.value}
                          </option>
                        ))}
                      </select>
                    </React.Fragment>
                  }
                </div>
                <div className="row m-2">
                  <React.Fragment><label className="font-weight-bold">Riga</label>
                    <div className="input-group">
                      <input
                        name="rowValue"
                        id="rowValue"
                        placeholder="Riga"
                        className="form-control"
                        inputMode="numeric"
                        type="number"
                        onChange={this.handleChangeLoc}
                      />
                    </div>
                  </React.Fragment>
                </div>
                <div className="row m-2">
                  <React.Fragment><label className="font-weight-bold">Colonna</label>
                    <div className="input-group">
                      <input
                        name="columnValue"
                        id="columnValue"
                        placeholder="Colonna"
                        className="form-control"
                        inputMode="numeric"
                        type="number"
                        onChange={this.handleChangeLoc}
                      />
                    </div>
                  </React.Fragment>
                </div>
                <div className="row m-2">
                  <React.Fragment><label className="font-weight-bold">Aggiungi righe</label>
                    <div className="input-group">
                      <input
                        name="addRow"
                        id="addRow"
                        value="0"
                        placeholder="Righe in più"
                        className="form-control"
                        inputMode="numeric"
                        type="number"
                        onChange={this.handleChangeLoc}
                      />
                    </div>
                  </React.Fragment>
                </div>
                {this.renderButtonModal(plusMessage, "modalOp", false, loadingSave, disableButton, () => { return this.handleModalMessage("s"); }, "btn btn-outline-success mr-2 mb-2")
                }

                <ModalNew modalId="modalOp" modalTitle={standardMessage.MODAL_TITLE_ATTENTION} modalBody={modalQuestion} onModalConfirm={this.handleModalConfirm} />
              </div>
            </div>
          </div>
        </React.Fragment>


      </div>
    );
  }
}
export { VoicePositionPage };
