var baseURL = "";
var root = window.location.protocol + '//' + window.location.host;
if (process.env.NODE_ENV !== "production") {
  baseURL = "http://localhost:5000";
} else {
  //baseURL = "https://www.afasolving.it";
  baseURL = root; //"https://www.afaservice.it";
}
var apiURL = baseURL + "/api/";
var welcomeURL = "welcome";
var emailAfa = "afasolving@hotmail.com";

var bstrp2col = "col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6";
var bstrp3col = "col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4";
var bstrp4col = "col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3";
var bstrp6col = "col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-2";
var bstrp12col = "col-xs-12 col-sm-12 col-md-12 col-lg-1 col-xl-1";
export const config = {
  apiUrl: apiURL,
  baseUrl: baseURL,
  welcomeURL: welcomeURL,
  emailAfa: emailAfa,
  pageSize: 8,
  autoCloseToast: 2000,
  bstrp2col: bstrp2col,
  bstrp3col: bstrp3col,
  bstrp4col: bstrp4col,
  bstrp6col: bstrp6col,
};
