import React from "react";

const Input = ({
  name,
  label,
  placeholder,
  disabled,
  // onfocus,
  error,
  ...rest
}) => {
  return (
    <div className="form-group mr-2">
      <label htmlFor={name} className="font-weight-bold">
        {label}
      </label>
      <div className="input-group">
        {(placeholder === "Valore in euro") && <div className="input-group-addon">EUR</div>}
        <input
          {...rest}
          name={name}
          id={name}
          placeholder={placeholder}
          className="form-control"
          disabled={disabled}
        //autoFocus={onfocus}
        />
      </div>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default Input;
