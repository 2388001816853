import http from "./httpService";
import { config } from "../_helpers";

export const cicassService = {
  getAll,
  getDefault,
  getById,
  getYesNo,
  getWithCustomUrl,
  deleteOp,
  saveOp,
  postArray,
  putArray,
  uploadFile,
  downloadFile
};

export const yesNoArray = [{ id: 0, name: "NO" }, { id: 1, name: "SI" }];

export function getYesNo() {
  return yesNoArray.filter(g => g);
}

function singleDataUrl(url, id) {
  return `${config.apiUrl}${url}/${id}`;
}

export function getAll(url) {
  return http.get(config.apiUrl + url);
}

export function getDefault(url) {
  return http.get(config.apiUrl + url + "/default");
}

export function getById(url, id) {
  return http.get(singleDataUrl(url, id));
}

export function getWithCustomUrl(url) {
  return http.get(config.apiUrl + url);
}

export function deleteOp(url, id) {
  return http.delete(singleDataUrl(url, id));
}

export function saveOp(url, data) {
  // if (data.id != -1 ) {
  //   const copyData = { ...data };
  //   delete copyData.id;
  //   return http.put(singleDataUrl(url, data.id), copyData);
  // }
  // return http.post(config.apiUrl + url, data);  
  const copyData = { ...data };
  delete copyData.id;
  if (data.id !== -1) {
    return http.put(singleDataUrl(url, data.id), copyData);
  }
  return http.post(config.apiUrl + url, copyData);
}

export function postArray(url, data) {
  const copyData = data.slice(0, data.length);
  for (var i = 0; i < copyData.length; i++) {
    delete copyData[i].id;
  }
  //console.log(copyData);
  return http.post(config.apiUrl + url, copyData);
}

export function putArray(url, data) {
  //console.log(copyData);
  return http.put(config.apiUrl + url, data);
}

export function uploadFile(fileUploaded) {
  return http.post(config.apiUrl + "upload", fileUploaded, {
    headers: { "Content-Type": "multipart/form-data" }
  });
  //return http.post(config.apiUrl + 'upload', fileUploaded,{ headers: {'Content-Type': undefined}});
  //   axios.post("http://localhost:8000/upload", data, {
  //       // receive two    parameter endpoint url ,form data
  //   })
  // .then(res => { // then print response status
  //     console.log(res.statusText)
  //  })
}
export function downloadFile(fileName, name) {
  return http.get(config.apiUrl + "upload/" + fileName, {
    responseType: "blob"
  }).then((response) => {
    //console.log(response);
    var newBlob = new Blob([response.data], { type: 'application/pdf' });
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    /*  window.navigator.msSaveOrOpenBlob(newBlob);
      return;*/
    //}
    var isIOS = /(iPhone|iPad|iPod)/.test(navigator.platform);
    var isChrome = /Google Inc/.test(navigator.vendor);

    if (isIOS && isChrome) {
      var file = new Blob([response.data], { type: 'application/pdf' });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return;
    }
    const url = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
}
