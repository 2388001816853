import React from "react";
import { NavLink } from "react-router-dom";
import simbolo from "../images/simbolo_afa.png";

const HeaderNav = ({ user }) => {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark static-top">
        <div className="container-fluid">
          <NavLink className="navbar-brand" to="/">
            <img src={simbolo} alt="Afa" height="30" />&nbsp;AFA solving
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <div className="navbar-nav">
              <NavLink className="nav-item nav-link" to="/contactus">
                Contattaci
              </NavLink>
            </div>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <NavLink className="nav-item nav-link" to="/policy">
                  Policy
                  </NavLink>
              </li>
              {!user && (
                <li className="nav-item">
                  <NavLink className="nav-item nav-link" to="/login">
                    Login
                  </NavLink>
                </li>
              )}
              {user && (
                <React.Fragment>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {user.given_name} {user.family_name}
                    </a>
                    <div
                      className="dropdown-menu dropdown-menu-right"
                      aria-labelledby="ciao"
                    >
                      <NavLink className="dropdown-item" to="/welcome">
                        Portale
                      </NavLink>
                      {/*<NavLink className="dropdown-item" to="/faq">
                        Aiuto
              </NavLink>
              
              *** Al momento disabilitato in attesa che dicano loro cosa inserire ***
              
              */}
                      <NavLink className="dropdown-item" to="/profile">
                        Profilo
                      </NavLink>
                      <div className="dropdown-divider"></div>
                      <NavLink className="dropdown-item" to="/logout">
                        Logout
                      </NavLink>
                    </div>
                  </li>
                </React.Fragment>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default HeaderNav;
