import React from "react";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import it from 'date-fns/locale/it';
registerLocale("it", it);

const DateTime = ({
  selected,
  label,
  error,
  ...rest
}) => {
  const startDt= new Date();
  return (
    <div className="form-group mr-2">
      <label htmlFor={selected} className="font-weight-bold">
        {label}
      </label>
      <div className="input-group">
        <DatePicker selected={selected} {... rest} className="form-control">
        </DatePicker>
      </div>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default DateTime;