import React from "react";
import { Link } from "react-router-dom";
import UserlistTable from "../../../_components/UserlistTable";
import ListGroup from "../../../_components/common/listGroup";
import Pagination from "../../../_components/common/pagination";
import { paginate } from "../../../utils/paginate";
import SearchBox from "../../../_components/common/searchBox";
import Form from "../../../_components/common/form";
import { cicassService, auth } from "../../../_services";
import { config, history } from "../../../_helpers";
import { ToastContainer, toast } from "react-toastify";
import spinner from "../../../images/form-preloader.gif";
import { standardMessage } from "../../../_constants/";
import _ from "lodash";

const apiUrl = "v_user_page";
const apiUrlDel = "ana_user";

class UserListPage extends Form {
  state = {
    myData: [], //cicassService.getAll(apiUrl)
    choices: [],
    pageSize: config.pageSize,
    currentPage: 1,
    searchQuery: "",
    selectedChoice: null,
    sortColumn: { path: "title", order: "asc" },
    pagina: "Utenti del Portale"
  };

  async componentDidMount() {
    const isExpired = auth.checkExpired();
    const { data: myData } = await cicassService.getAll(apiUrl);
    //console.log("ciao: ", myData);
    const choices2 = [{ id: "", name: "Gruppi" }];
    const gruppo = myData.map(myData => {
      choices2.push({ id: myData.groupname, name: myData.groupname });
    });
    const choices = [];
    const map = new Map();
    for (const item of choices2) {
      if (!map.has(item.id)) {
        map.set(item.id, true); // set any value to Map
        if (item.name !== "SuperUser") {
          choices.push({
            id: item.id,
            name: item.name
          });
        }
      }
    }

    this.setState({ myData, choices });
  }

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleDelete = async singleElem => {
    // Ho spostato la delete nel dettaglio
    const originalData = this.state.myData;
    const myData = this.state.myData.filter(p => p.id !== singleElem.id);
    this.setState({ myData });

    try {
      await cicassService.deleteOp(apiUrlDel, singleElem.id);
      toast.success("Riga eliminata.");
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("Non è stato possibile eliminare la riga.");
      this.setState({ myData: originalData });
    }
  };

  handleChoiceSelect = choice => {
    this.setState({ selectedChoice: choice, currentPage: 1 });
  };

  handleSearch = query => {
    this.setState({ searchQuery: query, currentPage: 1 }); //selectedChoice: null,
  };

  handleEdit = singleElem => {
    //console.log("Modifica", singleElem);
    //history.push(`/users/${singleElem.id}`);
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      selectedChoice,
      searchQuery,
      myData: allData
    } = this.state;

    let filtered = allData;
    filtered = filtered.filter(m => m.groupname !== "SuperUser");
    if (selectedChoice && selectedChoice.id) filtered = filtered.filter(m => m.groupname === selectedChoice.id);
    if (searchQuery) filtered = filtered.filter(m => m.name.toLowerCase().includes(searchQuery.toLowerCase()) || (m.surname.toLowerCase().includes(searchQuery.toLowerCase())));
    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    const myData = paginate(sorted, currentPage, pageSize);
    return { totalCount: filtered.length, data: myData };
  };

  render() {
    const { length: count } = this.state.myData;
    const {
      pageSize,
      currentPage,
      sortColumn,
      pagina,
      searchQuery
    } = this.state;

    if (count === 0) return (<React.Fragment>
      <div className="container-fluid">
        <div className="text-center">
          <img className="img-fluid" src={spinner} alt="loading..." />
        </div>
      </div>
    </React.Fragment>)

    const { totalCount, data: myData } = this.getPagedData();

    return (
      <div className="container-fluid">
        <ToastContainer autoClose={config.autoCloseToast} />
        <div className="row m-4">
          <div className="col">
            <h3>{pagina}</h3>
          </div>
          <div className="float-right pr-2">
            <Link className="btn btn-outline-success" to="/users/-1">
              {standardMessage.CMD_NEW}
            </Link>
          </div>
          <div className="float-right">
            {this.renderButtonLink(standardMessage.CMD_BACK, "/welcome")}
          </div>
        </div>
        <div className="row">
          <div className="d-none d-lg-block col-sm-2">
            <ListGroup
              items={this.state.choices}
              selectedItem={this.state.selectedChoice}
              onItemSelect={this.handleChoiceSelect}
            />
          </div>
          <div className="col-sm-10">
            {totalCount === 0 && (
              <p>
                Non sono stati trovati utenti con queste condizioni di ricerca.
              </p>
            )}
            {totalCount === 1 && <p>E' stato trovato un utente.</p>}
            {totalCount > 1 && <p>Sono stati trovati {totalCount} utenti.</p>}

            <SearchBox value={searchQuery} onChange={this.handleSearch} />
            <UserlistTable
              myData={myData}
              sortColumn={sortColumn}
              onDelete={this.handleDelete}
              onEdit={this.handleEdit}
              onSort={this.handleSort}
            />
            <Pagination
              key={totalCount}
              itemsCount={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

export { UserListPage };
