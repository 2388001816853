import React from "react";
import { Link } from "react-router-dom";
import SubscriptionlistTable from "../../../_components/SubscriptionlistTable";
import Pagination from "../../../_components/common/pagination";
import SearchBox from "../../../_components/common/searchBox";
import Form from "../../../_components/common/form";
import { paginate } from "../../../utils/paginate";
import { cicassService, auth } from "../../../_services";
import { config, history } from "../../../_helpers";
import { ToastContainer, toast } from "react-toastify";
import spinner from "../../../images/form-preloader.gif";
import { standardMessage } from "../../../_constants/";
import _ from "lodash";

const apiUrl = "ana_subscription";

class SubscriptionListPage extends Form {
  state = {
    myData: [], //cicassService.getAll(apiUrl)
    choices: [],
    pageSize: config.pageSize,
    currentPage: 1,
    searchQuery: "",
    selectedChoice: null,
    sortColumn: { path: "name", order: "asc" },
    pagina: "Contratti per Incarichi"
  };

  async componentDidMount() {
    const isExpired = auth.checkExpired();
    const { data: myData } = await cicassService.getAll(apiUrl);
    const choices = [{ id: "", name: "Incarichi" }];
    const gruppo = myData.map(myData => {
      choices.push({ id: myData.name, name: myData.name });
    });

    this.setState({ myData, choices });
  }

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleDelete = async singleElem => {
    const originalData = this.state.myData;
    const myData = this.state.myData.filter(p => p.id !== singleElem.id);
    this.setState({ myData });

    try {
      await cicassService.deleteOp(apiUrl, singleElem.id);
      toast.success("Riga eliminata.");
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("Non è stato possibile eliminare la riga.");
      this.setState({ myData: originalData });
    }
  };

  handleChoiceSelect = choice => {
    this.setState({ selectedChoice: choice, searchQuery: "", currentPage: 1 });
  };

  handleSearch = query => {
    this.setState({ searchQuery: query, selectedChoice: null, currentPage: 1 });
  };

  handleEdit = singleElem => {
    // console.log("Modifica", singleElem);
    //history.push(`/users/${singleElem.id}`);
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      selectedChoice,
      searchQuery,
      myData: allData
    } = this.state;

    let filtered = allData.filter(m => m.name !== "Cicass");
    if (searchQuery)
      filtered = allData.filter(m =>
        m.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    else if (selectedChoice && selectedChoice.id)
      filtered = allData.filter(m => m.name === selectedChoice.id);

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const myData = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: myData };
  };

  render() {
    const { length: count } = this.state.myData;
    const {
      pageSize,
      currentPage,
      sortColumn,
      pagina,
      searchQuery
    } = this.state;

    if (count === 0) return (<React.Fragment>
      <div className="container-fluid">
        <div className="text-center">
          <img className="img-fluid" src={spinner} alt="loading..." />
        </div>
      </div>
    </React.Fragment>)

    const { totalCount, data: myData } = this.getPagedData();

    return (
      <div className="container-fluid">
        <ToastContainer autoClose={config.autoCloseToast} />
        <div className="row m-4">
          <div className="col">
            <h3>{pagina}</h3>
          </div>
          <div className="float-right pr-2">
            <Link className="btn btn-outline-success" to="/subscriptions/-1">
              {standardMessage.CMD_NEW}
            </Link>
          </div>
          <div className="float-right">
            {this.renderButtonLink(standardMessage.CMD_BACK, "/welcome")}
          </div>
        </div>
        <div className="row">
          <div className="col">
            {totalCount === 0 && (
              <p>
                Non sono stati trovati contratti con queste condizioni di ricerca.
              </p>
            )}
            {totalCount === 1 && <p>E' stato trovato un contratto.</p>}
            {totalCount > 1 && <p>Sono stati trovati {totalCount} contratti.</p>}
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
            <SubscriptionlistTable
              myData={myData}
              sortColumn={sortColumn}
              onDelete={this.handleDelete}
              onEdit={this.handleEdit}
              onSort={this.handleSort}
            />
            <Pagination
              key={totalCount}
              itemsCount={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

export { SubscriptionListPage };
