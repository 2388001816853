import React, { Component } from "react";
import Table from "./common/table";
import { Link } from "react-router-dom";
import { standardMessage } from '../_constants';
import DownPDF from "../images/icons/dwnpdf_48.png";
import AcceptIcon from "../images/icons/folder_check.png";
import DenyIcon from "../images/icons/folder_uncheck.png";

class QuotationlistTable extends Component {
  columns = [
    { path: "owner", label: "Affiliato" },
    {
      path: "accountholder",
      label: "Cliente Finale",
      invisible: true
    },
    {
      path: "type",
      label: "Tipo",
      invisible: true
    },
    { path: "lastupdate_date", label: "Ultima modifica", invisible: true, date: true },
    { /*path: "acceptedat", label: "Accettato il", invisible: true, date: true */ },
    {
      label: "Azioni",
      key: "edit",
      content: choice => (
        <React.Fragment>

          {/*
          <button
          //onClick={() => this.props.onEdit(choice)} //this.props.handleDelete(row)
          className="btn btn-outline-dark"
        >
          
        </button>
          */}
          <Link
            style={{ color: "inherit", textDecoration: "inherit", paddingRight: "15px" }}
            to={`/quotations/${choice.id}`}
          >
            <i className="far fa-edit fa-2x clickable" title="Modifica"></i>
          </Link>
          <i className="fas fa-file-pdf fa-2x clickable" title="Download PDF" style={{ color: "#ff3939", paddingRight: "15px" }} onClick={() => this.props.onDown(choice.id, choice.accountholder)}></i>
          {/*<i className="far fa-check-circle fa-2x clickable" title="Accetta Preventivo" style={{ color: choice.isaccepted == 0 ? "#B8B8B8" : "#66ff00" }} onClick={() => this.props.onAccept(choice.id, (1 - choice.isaccepted))}></i>*/}
          <img src={choice.isaccepted == 1 ? AcceptIcon : DenyIcon} className="clickable" title="Accetta Prospetto spesa"
            style={{ marginTop: "-12px" }} width="32" onClick={() => this.props.onAccept(choice.id, (1 - choice.isaccepted))}></img>
        </React.Fragment>
      ) //
    }
    /*  {
      key: "delete",
      content: choice => (
        <button
          onClick={() => this.props.onDelete(choice)} //this.props.handleDelete(row)
          className="btn btn-outline-danger"
        >
          Elimina
        </button>
      )
    }*/
  ];

  render() {
    const { myData, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={myData}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default QuotationlistTable;
