import React, { Component } from "react";
import Table from "./common/table";
import { Link } from "react-router-dom";
import { standardMessage } from "../_constants/";

class SubscriptionlistTable extends Component {
  columns = [
    { path: "name", label: "Descrizione" },
    { path: "mandate_value", label: "Nr Incarichi" },
    { path: "mandate_period", label: "Periodo" },
    { path: "isPackage", label: "A pacchetto" , isBool: true},
    {
      key: "edit",
      content: choice => (
        <button
          onClick={() => this.props.onEdit(choice)}
          className="btn btn-outline-dark"
        >
          <Link
            style={{ color: "inherit", textDecoration: "inherit" }}
            to={`/subscriptions/${choice.id}`}
          >
            {standardMessage.CMD_DETAILS}
          </Link>
        </button>
      ) //
    }
  ];

  render() {
    const { myData, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={myData}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default SubscriptionlistTable;
