import React from "react";
import Parag from "../../_components/common/parag";

var styleTable = { border: "1px solid #ddd", padding: "10px" };

class PolicyPage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="container-fluid mt-3">
          <h2>Informazioni sul trattamento dei dati personali e cookie policy ex articoli 13 e 14 del Regolamento UE 2016/679 (“GDPR”)</h2>
          <Parag title="">
            Le informazioni indicate di seguito vengono rese ai sensi degli articoli 13 e 14 del Regolamento UE 2016/679 (“GDPR”) a coloro che abbiano accesso al
            sito Internet <a href="https://www.afasolving.it" target="_blank">www.afasolving.it</a>.<br />
            In questa informativa si descrivono le modalità di gestione del sito, in riferimento al trattamento dei dati personali degli utenti, in quanto,
            a seguito della sua consultazione e/o della navigazione all’interno di esso, possono essere trattati dati relativi a persone identificate o identificabili.<br />
            L'informativa è resa solo per il sito <a href="https://www.afasolving.it" target="_blank">www.afasolving.it</a> (“Sito”) e non anche per altri siti web eventualmente consultati dall'utente tramite i link in esso contenuti.<br />
            <b>AFA solving s.r.l.</b> (da qui in avanti anche “AFA solving” o, semplicemente, “la Società”) è titolare dei trattamenti operati sui dati.
          </Parag>

          <Parag title="1. TITOLARE DEL TRATTAMENTO">
            AFA solving s.r.l., con sede in Milano, Viale Rodi n. 85, P.IVA 10730180964, è titolare, ai sensi dell’articolo 4, n. 7, e 24, del GDPR,
            dei trattamenti operati nell’ambito del presente Sito, sui dati dei soggetti che accedono al Sito ovvero navigano e/o richiedono servizi
            per tramite del Sito stesso.<br />
            Il trattamento dei dati sarà eseguito da dipendenti o collaboratori della Società comunque operanti nell’ambito della stessa,
            in funzione di soggetti autorizzati al trattamento.
          </Parag>


          <Parag title="2. FINALITA’ DEL TRATTAMENTO">
            Il trattamento sui dati di navigazione verrà condotto per finalità di:
            <ol type="1">
              <li>gestione del Sito Internet;</li>
              <li>risposta alle richieste pervenute mediante la scheda “Contattaci” e/o la email <a href="mailto:afasolving@hotmail.com">afasolving@hotmail.com</a>;</li>
              <li>analisi delle statistiche d’accesso e di navigazione.</li>
            </ol>
            <b>Base legale del trattamento e tempo di conservazione</b><br />
            Il conferimento dei dati nell’ambito della navigazione sul Sito (ad eccezione dei dati trattati in virtù del funzionamento dei protocolli di Internet e dei cookie)
            è facoltativo.
            I dati personali forniti espressamente dagli utenti/interessati che, ad esempio, inoltrino richieste di informazioni,
            sono trattati in coerenza con il tempo nell’ambito del quale permane l’interesse del soggetto richiedente, a ricevere informazioni.
            Inoltre, l’utente/interessato viene informato che, essendo prevista – sia per ragioni tecniche, sia per la gestione del Sito – la generazione e conservazione
            di copie di backup dei dati, alcune tipologie di informazioni possono continuare a persistere nei sistemi, anche dopo la cancellazione
            (per un periodo variabile ma comunque strettamente correlato con le procedure di backup e con il piano di <i>disaster recovery</i>).
          </Parag>

          <Parag title="2.1 TIPI DI DATI TRATTATI">
            La semplice consultazione del Sito non comporta di per sé la raccolta o il trattamento dei dati personali degli utenti salvo per quanto concerne i dati
            di navigazione ed i cookies tecnici.<br />
            <br /><b>Dati di navigazione</b><br />
            I sistemi informatici e le procedure software preposte al funzionamento del Sito acquisiscono, nel corso del suo normale esercizio, alcuni dati la cui
            trasmissione è implicita nell’uso dei protocolli di comunicazione di Internet.<br />
            Si tratta di informazioni che non consentono di identificare direttamente l’utente/interessato, ma che per loro stessa natura possono, attraverso elaborazioni
            ed associazioni con dati detenuti da terzi, permettere l’identificazione degli utenti/interessati.<br />
            In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli utenti che si connettono ai Siti, gli indirizzi in notazione URI
            (Uniform Resource Identifier) delle risorse richieste, l’orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto
            in risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, ecc.) ed altri parametri relativi al sistema operativo e all’ambiente
            informatico dell’utente.<br />
            Questi dati non vengono diffusi, ma sono utilizzati al solo fine di ricavare informazioni statistiche anonime sull'uso del Sito e per controllarne il corretto funzionamento
            e sono conservati per il tempo necessario al perseguimento delle finalità illustrate. Inoltre, i dati potrebbero, comunque, essere utilizzati per l'accertamento di
            responsabilità in caso di attività delittuose o comunque illecite perpetrate ai danni del Sito ovvero attraverso di esso.<br />
            <br /><b>Cookies</b><br />
            Un cookie è un piccolo file di testo che viene memorizzato dal device (computer, tablet, smartphone) nel momento in cui un Sito web viene visitato da un utente.
            Un cookie può memorizzare talune informazioni che il Sito è in grado di leggere allorquando, in occasione di un nuovo accesso, venga consultato nuovamente.
            Alcuni di questi cookie sono necessari al corretto funzionamento del Sito, altri sono invece utili al visitatore perché in grado di memorizzare in modo sicuro ad esempio
            il nome utente o le impostazioni di lingua. Il vantaggio di avere dei cookie installati nel proprio pc è quello di non aver più bisogno di compilare le stesse informazioni
            ogniqualvolta si voglia accedere ad un sito visitato in precedenza.<br />
            AFA solving utilizza i cookie al fine di fornire ai propri utenti un’esperienza di navigazione modellata il più possibile intorno alle preferenze dell’utente medesimo
            e in modo che ad ogni visita del Sito non sia necessario inserire, nuovamente, le stesse informazioni. I cookie vengono utilizzati anche al fine di ottimizzare le prestazioni
            del Sito web: essi infatti rendono più facile sia la navigazione che la ricerca veloce di elementi specifici all’interno del Sito.<br />
            <br /><i>Cookie tecnici</i><br />
            Questi cookie sono costantemente abilitati e sono necessari per eseguire le funzionalità di base del Sito. Si tratta di cookie che consentono di riconoscere l’utente che
            utilizza il Sito durante un’unica sessione o, se richiesto, anche nelle sessioni successive.<br />
            I cookie utilizzati dal nostro Sito sono i seguenti:<br /><br />
            <table style={styleTable}>
              <thead style={{ background: "#F5F5F5" }}>
                <tr style={styleTable}>
                  <th style={styleTable}>Nome cookie</th>
                  <th style={styleTable}>Descrizione</th>
                  <th style={styleTable}>Persistenza</th>
                </tr>
              </thead>
              <tbody>
                <tr style={styleTable}>
                  <td style={styleTable}>myAwesomeCookieName2</td>
                  <td style={styleTable}>Contiene l'informazione relativa al consentire o meno questa policy durante il primo ingresso sul Sito.</td>
                  <td style={styleTable}>365 giorni</td>
                </tr>
              </tbody>
            </table>
            <br />L’utente può configurare, liberamente ed in qualsiasi momento, le proprie preferenze “privacy”, in relazione all’installazione ed uso dei cookies,
            modificando le impostazioni del proprio browser.<br />
            In particolare l’utente può impostare la cosiddetta “navigazione in incognito”, in questo modo il browser interrompe la memorizzazione della cronologia dei siti visitati,
            delle password inserite, dei cookies e delle altre informazioni che generalmente vengono raccolte.<br />
            Si tenga presente che, nel caso in cui l’utente decida di disattivare tutti i cookies (anche quelli di natura tecnica), la qualità e la rapidità dei servizi offerti dal sito
            potrebbero peggiorare e l’accesso ad alcune sezioni del Sito stesso potrebbe essere impraticabile.<br />
            <br /><b>Informazioni inviate spontaneamente dall’utente</b><br />
            Le informazioni inviate spontaneamente, attraverso la email <a href="mailto:afasolving@hotmail.com">afasolving@hotmail.com</a> o secondo altre modalità, saranno trattate nel rispetto della normativa vigente.
            Qualora, tuttavia, le informazioni inviate non siano pertinenti e siano eccedenti rispetto al servizio richiesto o allo scopo per cui la Società ha reso disponibile
            i propri contatti, AFA solving si riserva il diritto di procedere alla cancellazione di quei dati che ritiene inappropriati rispetto al canale di comunicazione
            utilizzato (si pensi ad esempio all’invio di documentazione sanitaria ai contatti generici della Società).
          </Parag>

          <Parag title="3. AMBITO DI COMUNICAZIONE e DIFFUSIONE DEI DATI">
            I dati sopra descritti saranno trattati da AFA solving s.r.l. o da soggetti da questa designati in qualità di responsabili del trattamento (articolo 28 del GDPR),
            nel territorio italiano o dell’Unione Europea. Essi potranno essere comunicati a soggetti terzi incaricati di fornire servizi connessi alle attività del Sito e,
            in particolare, a fornitori di servizi tecnologici e telematici, i quali provvederanno alla temporanea archiviazione degli stessi ed al loro trattamento
            esclusivamente per le finalità definite al § 2 (finalità del trattamento).
          </Parag>
          <Parag title="4. MODALITÀ DI TRATTAMENTO">
            I dati generati in occasione della navigazione sul Sito, sono trattati con strumenti automatizzati e/o manualmente, per il tempo strettamente necessario a conseguire
            gli scopi per cui sono stati raccolti.
            Il trattamento avverrà in ogni caso mediante l'ausilio di strumenti e procedure idonei a garantirne la sicurezza e la riservatezza in conformità alla normativa di
            riferimento. Specifiche misure di sicurezza sono osservate per prevenire la perdita dei dati, usi illeciti o non corretti ed accessi non autorizzati.
          </Parag>

          <Parag title="5. DIRITTI DELL’INTERESSATO">
            Chiunque si trovi nella posizione di interessato/utente può, in qualunque momento, esercitare i diritti di cui agli articoli 15-22 del GDPR e, in particolare,
            ottenere dal titolare del trattamento la conferma che sia o meno in corso un trattamento di dati personali che la riguardano e in tal caso, ha diritto di accedere agli stessi.<br />
            Inoltre ha diritto di chiedere al titolare del trattamento la rettifica o la cancellazione dei dati personali o la limitazione del trattamento che la riguarda o di
            opporsi al loro trattamento, oltre al diritto alla portabilità dei dati.<br />
            L’interessato ha altresì il diritto di proporre reclamo all’Autorità di controllo (in Italia si tratta del Garante per la protezione dei dati personali).<br />
            Per esercitare i propri diritti o per qualsiasi informazione in merito alla tutela dei suoi dati personali, l’interessato può rivolgersi a <b>AFA solving s.r.l.</b>,
            scrivendo alla sede sita in Viale Rodi n. 85, (20126) Milano, oppure inviando una email a <a href="mailto:afasolving@hotmail.com">afasolving@hotmail.com</a>.

          </Parag>
        </div>
      </React.Fragment>
    )
  }
}

export { PolicyPage };
