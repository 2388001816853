import React, { Component } from "react";
import Table from "./common/table";

class VoicepositionlistTable extends Component {
  columns = [
    { path: "name", label: "Nome" },
    { path: "value", label: "Valore" },
    { path: "quotationtype", label: "Tipo" },
    { path: "rowValue", label: "Riga" },
    { path: "columnValue", label: "Colonna" },
    { path: "addRow", label: "Righe in più" }
  ];

  render() {
    const { myData, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={myData}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default VoicepositionlistTable;
