import React from "react";
import { Link } from "react-router-dom";
import { cicassService, auth } from "../../_services";
import Statistics from "../../_components/Statistics";
import Card from "../../_components/common/card";
import iQuotations from "../../images/quotation.jpg";
import iUsers from "../../images/users.jpg";
import iAgencies from "../../images/agencies.jpg";
import iSettings from "../../images/settings.jpg";
import iSubscriptions from "../../images/docs.jpg";
import iLogins from "../../images/logins.jpg";
import { isUserWhitespacable } from "@babel/types";
import spinner from "../../images/form-preloader.gif";
import iFaloosa from "../../images/faloosa.svg";

const apiAgencyUrl = "ana_user/agencyvalue";

class WelcomePage extends React.Component {

  state = {
    visibleAgency: false,
    visibleWelcome: false //avoid the sfarfallio
  };

  async componentDidMount() {
    const av = await cicassService.getAll(apiAgencyUrl);
    //if (av.data >= 0) this.setState({ visibleAgency: true });
    if (av.data) this.setState({ visibleAgency: (av.data.agency_value_is_visible == 1) });
    this.setState({ visibleWelcome: true });
  }

  render() {
    const { user } = this.props; // verificare sempre che non sia undefined
    const {
      visibleWelcome
    } = this.state;
    return (
      <div className="container-fluid mt-3">
        {auth.isRoleOK(auth.HighRole, user) && <Statistics />}

        <h3>
          Buongiorno <b>{user && user.given_name}</b>, benvenuto nell'
          <b>Area Privata</b> di AFA solving.
        </h3>

        <p>A seguire le sezioni attive all'interno della tua zona riservata.</p>
        {!visibleWelcome && (<React.Fragment>
          <div className="text-center">
            <img className="img-fluid" src={spinner} alt="loading..." />
          </div>
        </React.Fragment>)}
        {user && visibleWelcome && (<div className="row">
          <Card
            img={iQuotations}
            label="Prospetti spesa"
            text="Gestire, creare, modificare, stampare, visualizzare storico."
            btnText="Vai"
            destination="/quotations"
          />

          {auth.isRoleOK(auth.HighRole, user) && (
            <React.Fragment>
              <Card
                img={iUsers}
                label="Utenti"
                text="Gestire, creare, modificare, disabilitare gli utenti."
                btnText="Vai"
                destination="/users"
              />
              <Card
                img={iAgencies}
                label="Agenzie"
                text="Gestire, creare, modificare, disabilitare le agenzie."
                btnText="Vai"
                destination="/agencies"
              />
              <Card
                img={iSubscriptions}
                label="Contratti per Incarichi"
                text="Gestire, creare, modificare, disabilitare i contratti per gli incarichi."
                btnText="Vai"
                destination="/subscriptions"
              />
            </React.Fragment>
          )
          }

          {this.state.visibleAgency && (<Card
            img={iSettings}
            label="Impostazioni"
            text="Gestire le impostazioni del Portale."
            btnText="Vai"
            destination="/settings"
          />)}

          <Card
            img={iFaloosa}
            label="Attività/Pratiche"
            text="Gestire, creare, modificare attività e pratiche."
            btnText="Vai"
            target="https://www.faloosa.it/login"
          />



        </div>)}
      </div >
    );
  }
}

export { WelcomePage };
