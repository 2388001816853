import React, { Component } from "react";

export default class Footer extends Component {


  render() {
    let d = new Date();

    return (
      <React.Fragment>
        <footer className="footer">
          <div style={{ paddingLeft: "15px", fontSize: "12px" }}>
            <span className="text-muted">
              AFA solving s.r.l. | Sede Legale: viale Rodi 85, 20126 Milano | Cod.Fisc./P.IVA:10730180964 |
              pec: <a href="mailto:afasolving@pec.it">afasolving@pec.it</a> | Uff.Registro iscritti: Milano Monza Brianza Lodi | REA: MI-2553162 |
              Capitale Sociale: 10000&euro; | Multiservizi Immobiliare &copy; Copyright
              2019 - {d.getFullYear()}
            </span>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}
