import * as JWT from "jwt-decode";
import jwtDecode from "jwt-decode";
import http from "./httpService";
import { config } from "../_helpers";

//const url = "users/authenticate";

const apiEndpoint = config.apiUrl + "ana_login";
const apiAuth = config.apiUrl + "auth";

const tokenKey = "token";
const superuser = "SuperUser";
const admin = "Admin";
const affiliato = "Affiliato";
const SuperRole = "SuperRole";
const HighRole = "HighRole";
const AdminRole = "AdminRole";
const OperatorRole = "OperatorRole";
const ARole = "ARole";

http.setJwt(getJwt());
//http.setJwt(getCurrentUser());

export async function login(username, password) {
  const { data: jwt } = await http.post(apiAuth +  "/authenticateafa", { username, password });
  localStorage.setItem(tokenKey, JSON.stringify(jwt));
}

export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt); //verificare se necessario???
}

export function logout() {
  localStorage.removeItem(tokenKey);
}

export function getCurrentUser() {
  try {
    const jwt = JSON.parse(localStorage.getItem(tokenKey)).token;

    //check if expired
    const tok = jwtDecode(jwt);
    if (tok.exp < Date.now() / 1000) {
      localStorage.removeItem(tokenKey);
      return null;
    }
    //localStorage.getItem(tokenKey);
    //console.log("Decode: ", JWT(jwt.token));
    return JWT(jwt);
  } catch (ex) {
    //console.log(ex);
    return null;
  }
}

export function getJwt() {
  const jwt = JSON.parse(localStorage.getItem(tokenKey));
  if (jwt) return "Bearer " + JSON.parse(localStorage.getItem(tokenKey)).token; //localStorage.getItem(tokenKey);
  return null;
}

export function isRoleOK(policy, user) {
  //const user = this.getCurrentUser();
  if (!user) return false;
  const role = user.role;

  switch (
  policy // CASES from Server-side AfaSrv C# services.AddAuthorization
  ) {
    case SuperRole:
      return role === superuser;
    case HighRole:
      return role === superuser || role === admin;
    case AdminRole:
      return role === admin;
    case OperatorRole:
      return role === affiliato;
    case ARole:
      return role === superuser || role === admin || role === affiliato;
    default:
      return false;
  }
}

export function checkExpired() {
  const jwt = JSON.parse(localStorage.getItem(tokenKey)).token;
  const tok = jwtDecode(jwt);
  if (tok.exp < Date.now() / 1000) {
    localStorage.removeItem(tokenKey);
    return true;
  }
  return false;
}

export async function resetPassword(userIdToReset) {
  await http.post(apiEndpoint + '/reset-password/' + userIdToReset);
}

export async function changePassword(oldPassword, newPassword) {
  let uri = apiEndpoint + '/change-password';
  let res = encodeURI(uri);
  await http.post(res, {
    oldPwd: oldPassword,
    newPwd: newPassword
  });
}

export async function create(data) {
  const copyData = { ...data };
 return await http.post(apiAuth + '/create', copyData);
}

export async function update(data) {
  const copyData = { ...data };
  delete copyData.id;
  return await http.put(apiAuth + '/update/' + data.id , copyData);
}

export async function enable(id) {
 return await http.get(apiAuth + '/enable/' + id);
}

export const auth = {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt,
  isRoleOK,
  checkExpired,
  changePassword,
  resetPassword,
  create,
  update,
  enable,
  SuperRole: SuperRole,
  HighRole: HighRole,
  AdminRole: AdminRole,
  OperatorRole: OperatorRole,
  ARole: ARole
};
