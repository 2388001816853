import React from "react";

const NotFound = () => {
  return (
    <h3>
      Attenzione: sembra che sia stata cercata una pagina inesistente oppure
      obsoleta.
    </h3>
  );
};

export default NotFound;
