import React, { Component } from "react";
import Table from "./common/table";

class AgencySubRefillListTable extends Component {
  columns = [
    { path: "refill_year", label: "Anno" },
    { path: "refill_month", label: "Mese" },
    { path: "refill_value", label: "Refill" }

  ];

  render() {
    const { myData, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={myData}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default AgencySubRefillListTable;
