import React from "react";
import { Redirect } from "react-router-dom";
import Joi from "joi-browser";
import Form from "../../_components/common/form";
import { auth } from "../../_services/authService";
import { config } from "../../_helpers";
import logo from "../../images/logo_afa.png";

const apiUrl = "users/authenticate";

class LoginPage extends Form {
  state = {
    data: { username: "", password: "", iar: false },
    firstTime: !localStorage.getItem("readIar"),
    errors: {},
    loading: false
  };

  schema = {
    username: Joi.string()
      .email()
      .required()
      .label("Username"),
    password: Joi.string()
      .required()
      .label("Password"),
    iar: Joi.boolean()
  };

  doSubmit = async () => {
    this.setState({ loading: true });
    try {
      //console.log("qui ci tento");
      const { data } = this.state;
      await auth.login(data.username, data.password);
      const { state } = this.props.location;
      //console.log(state);
      this.setState({ loading: false });
      localStorage.setItem('readIar', this.state.data.iar);
      window.location = state ? state.from.pathname : config.welcomeURL;
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
        this.setState({ loading: false });
      }
      //console.log("ex: ", ex);
    }
  };

  render() {
    const { loading, data, firstTime } = this.state;
    if (auth.getCurrentUser()) return <Redirect to={`${config.welcomeURL}`} />;

    return (
      <div className="container-fluid mt-5">
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mx-auto justify-content-center shadow p-3 mb-5 bg-white rounded">
          <img src={logo} alt="Logo" width="150" />
          <p>
            <br /> Prego, identificarsi per accedere all'<b>Area Privata</b>
          </p>
          <br />
          <form onSubmit={this.handleSubmit}>
            {this.renderInput(
              "username",
              "Username",
              "Inserire Username",
              "email",
              false,
              null
            )}
            {this.renderInput(
              "password",
              "Password",
              "Inserire Password",
              "text",
              false,
              null,
              "password"
            )}


            {firstTime && this.renderInputCheckBox("iar",
              "iar",
              "Ho letto le Informazioni sui dati personali",
              "(qui)",
              "docs/iar.pdf",
              "_blank"
            )}

            {(data.iar || !firstTime) && this.renderButton("Login", false, loading)}
          </form>
        </div>
      </div>
    );
  }
}

export { LoginPage };
