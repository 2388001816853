import React from "react";
import Joi from "joi-browser";
import Form from "../../../_components/common/form";
import Modal from "../../../_components/common/modal";
import { cicassService, auth } from "../../../_services";
import { config, history } from "../../../_helpers";
import { ToastContainer, toast } from "react-toastify";
import { standardMessage } from "../../../_constants/";

const apiUrl = "ana_subscription";
const apiUrlSubsriptionType = "ana_subscription_type/combo";

class SubscriptionDetailPage extends Form {
  _isMounted = false;
  state = {
    data: {
      id: -1,
      name: "",
      mandate_value: 0,
      anaSubscriptionTypeId: 1,
      isPackage: 0
    },
    isDefault: 0,
    isHidden: 0,
    viewOrder: 4,
    errors: {},
    disableInput: false,
    loadingSave: false,
    loadingDelete: false,
    disableButton: false,
    saveMessage: standardMessage.CMD_NEW,
    modalQuestion: "",
    anaSubscriptionTypes: [],
    packages: cicassService.getYesNo()
  };

  schema = {
    id: Joi.number(),
    name: Joi.string()
      .required().max(50).label("Descrizione"),
    mandate_value: Joi.number()
      .required().label("Nr Incarichi"),
    anaSubscriptionTypeId: Joi.number()
      .required().label("Periodo"),
    isPackage: Joi.number().required().label("A pacchetto")
  };

  async componentDidMount() {
    this._isMounted = true;
    const isExpired = auth.checkExpired();
    const dataId = this.props.match.params.id;
    let { data } = this.state;
    let saveMessage = standardMessage.CMD_NEW;
    const { data: anaSubscriptionTypes } = await cicassService.getAll(apiUrlSubsriptionType);
    if (Number(dataId) != -1) {
      let { data: myData } = await cicassService.getById(apiUrl, dataId);
      data = this.mapToViewModel(myData);
      saveMessage = standardMessage.CMD_EDIT;
    }

    if (this._isMounted) {
      if (data.id === 0) {
        history.replace("/not-found");
      }
      this.setState({ anaSubscriptionTypes, data, isDefault: data.isDefault, isHidden: data.isHidden, viewOrder: data.viewOrder, saveMessage, disableInput: (data.id == 1 ? true : false) });
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  mapToViewModel(data) {
    return {
      id: data.id,
      name: data.name,
      mandate_value: data.mandate_value,
      anaSubscriptionTypeId: data.ana_subscription_type_id,
      isPackage: data.isPackage
    };
  }

  handleModalMessage = idBtn => {
    switch (idBtn) {
      case "d": {
        this.setState({ modalQuestion: standardMessage.MODAL_QUESTION_DELETE });
        break;
      }
      case "s": {
        this.setState({ modalQuestion: standardMessage.MODAL_QUESTION_SAVE });
        break;
      }
      default:
        break;
    }
  };

  handleModalConfirm = () => {
    switch (this.state.modalQuestion) {
      case standardMessage.MODAL_QUESTION_DELETE: {
        this.handleDelete();
        break;
      }
      case standardMessage.MODAL_QUESTION_SAVE: {
        this.doSubmit();
        break;
      }
      default:
        break;
    }
  };

  handleDelete = async () => {
    this.setState({ loadingDelete: true, disableButton: true });
    try {
      await cicassService.deleteOp(apiUrl, this.state.data.id);
      toast.success(standardMessage.CMD_DELETE_OK);
      this.setState({ loadingDelete: false });
      window.location = "/subscriptions";
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error(standardMessage.CMD_DELETE_KO);
      this.setState({ loadingDelete: false });
    }
    this.setState({ disableButton: false });
  };

  doSubmit = async () => {
    this.setState({ loadingSave: true, disableButton: true });
    // SALVO in ANA_LOGIN
    let d = new Date();
    let { data, isDefault, isHidden, viewOrder } = this.state;
    const dataToSave = {
      id: data.id,
      name: data.name,
      mandate_value: Number(data.mandate_value),
      ana_subscription_type_id: Number(data.anaSubscriptionTypeId),
      isDefault: isDefault,
      isHidden: isHidden,
      viewOrder: viewOrder,
      isPackage: Number(data.isPackage),
      createdAt: d,
      updatedAt: null,
      deletedAt: null
    };
    //console.log(dataToSave);
    const dbAna = await cicassService.saveOp(apiUrl, dataToSave);
    toast.success(standardMessage.CMD_SAVE_OK);
    //this.props.history.push("/users");
    this.setState({ loadingSave: false, disableButton: false });
    window.location = "/subscriptions";
  };

  render() {
    const { loadingSave, loadingDelete, saveMessage, disableButton, modalQuestion, disableInput, anaSubscriptionTypes } = this.state;
    const dataId = this.props.match.params.id;
    return (
      <div className="container-fluid">
        <ToastContainer autoClose={config.autoCloseToast} />
        <div className="row m-4">
          <div className="col">
            <h1>Contratto per Incarichi</h1>
          </div>
          <div className="float-right">
            {this.renderButtonLink(standardMessage.CMD_BACK, "/subscriptions")}
          </div>
        </div>
        <div>
          <form onSubmit={this.handleSubmit}>
            <div className="row m-2">
              <div className={config.bstrp3col}>{this.renderInput("name", "Descrizione", "Descrizione Contratto", "text", disableInput)}</div>
              <div className={config.bstrp6col}>{this.renderInput("mandate_value", "Nr.Incarichi", "Nr.Incarichi")}</div>
              <div className={config.bstrp6col}>{this.renderSelect("anaSubscriptionTypeId", "Periodo", anaSubscriptionTypes)}</div>
              <div className={config.bstrp6col}>{this.renderSelect("isPackage", "A pacchetto", this.state.packages)}</div>
            </div>
            {this.renderButtonModal(saveMessage, "modalOp", true, loadingSave, (disableInput ? disableInput : disableButton), () => { return this.handleModalMessage("s"); }, "btn btn-outline-success ml-4 mr-4 mb-4")}
            {dataId > -1 &&
              this.renderButtonModal(standardMessage.CMD_DELETE, "modalOp", true, loadingDelete, (disableInput ? disableInput : disableButton), () => { return this.handleModalMessage("d"); }, "btn btn-outline-danger mb-4")}
          </form>
        </div>
        <Modal modalId="modalOp" modalTitle={standardMessage.MODAL_TITLE_ATTENTION} modalBody={modalQuestion} onModalConfirm={this.handleModalConfirm} />
      </div>
    );
  }
}
export { SubscriptionDetailPage };
