import React, { Component } from "react";
import { Link } from "react-router-dom";
import Joi from "joi-browser";
import Input from "./input";
import InputCheckBox from "./inputcheckbox";
import TextArea from "./textarea";
import Select from "./select";
import SelectWithId from "./selectwithid";
import DateTime from "./datetime";

//import MacroPracticeTypeComponent from "./macroPracticeType";

class Form extends Component {
  state = {
    data: {},
    errors: {}
  };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleSubmit = e => {
    e.preventDefault();

    const errors = this.validate();
    //console.log(errors);
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.doSubmit();
  };

  handleDatePicker = (name, selectedDate) => {
    const { data } = this.state;
    if (selectedDate != null) {
      data[name] = selectedDate;
      this.setState({ data, objChanged: true });
    }
  }

  handleChange = ({ currentTarget: input }) => {
    const value = input.type === "checkbox" ? input.checked : input.value;
    const errors = { ...this.state.errors };
    if (!(input.type === "checkbox")) {
      const errorMessage = this.validateProperty(input);
      if (errorMessage) errors[input.name] = errorMessage;
      else delete errors[input.name];
    }
    const data = { ...this.state.data };
    data[input.name] = value;
    this.setState({ data, errors });
  };

  handleChangeArray = ({ currentTarget: input }) => {
    const value = input.type === "checkbox" ? input.checked : input.value;
    const data = { ...this.state.data };
    //console.log(input.name + '['+input.id+']=' + value);
    data[input.name][input.id] = value;
    this.setState({ data });
    //console.log(this.state.data);
  };
  renderButton(
    label,
    validate,
    loading,
    bClass = "btn btn-outline-dark",
    sClass = "spinner-border spinner-border-sm"
  ) {
    let validateLocal = validate && this.validate();
    if (loading) {
      return (
        <button className={bClass} disabled>
          <span className={sClass} role="status" aria-hidden="true"></span>
          {label}
        </button>
      );
    }
    return (
      <button disabled={validateLocal} className={bClass}>
        {label}
      </button>
    );
  }

  renderButtonModal(
    label,
    idModal,
    validate,
    loading,
    disabled,
    onhandleModal,
    bClass = "btn btn-outline-dark",
    sClass = "spinner-border spinner-border-sm"
  ) {
    //console.log("Rendiamo", validate, this.validate());
    let validateLocal = validate && this.validate();
    if (loading) {
      return (
        <button className={bClass} disabled>
          <span className={sClass} role="status" aria-hidden="true"></span>
          {label}
        </button>
      );
    }

    return (
      <button
        type="button"
        disabled={validateLocal || disabled}
        className={bClass}
        data-toggle="modal"
        data-target={`#${idModal}`}
        onClick={() => onhandleModal()}
      >
        {label}
      </button>
    );
  }

  renderButtonLink(label, url, bClass = "btn btn-outline-dark") {
    return (
      <Link className={bClass} to={url}>
        {label}
      </Link>
    );
  }

  renderButtonLinkBottom(label, url, bClass = "btn btn-outline-dark") {
    return (
      <Link className={bClass} to={url} style={{ position: 'absolute', bottom: '16px' }}>
        {label}
      </Link>
    );
  }

  renderSelect(name, label, options, customFn = null) {
    const { data, errors } = this.state;
    return (
      <Select
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={customFn ? customFn : this.handleChange}
        error={errors[name]}
      />
    );
  }

  renderSelectWithId(name, label, options, customFn = null) {
    const { data, errors } = this.state;
    return (
      <SelectWithId
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={customFn ? customFn : this.handleChange}
        error={errors[name]}
      />
    );
  }

  renderInput(
    name,
    label,
    placeholder,
    inputMode,
    disabled = false,
    //onFocus = false,
    pattern = null,
    type = "text"
  ) {
    const { data, errors } = this.state;

    //switch inputMode in order to set type
    switch (inputMode) {
      case "email":
        type = "email";
        break;
      case "date":
        type = "date";
        break;
      case "numeric":
        type = "number";
        break;
    }

    return (
      <Input
        type={type}
        inputMode={inputMode}
        {...(pattern ? (pattern = { pattern }) : null)}
        name={name}
        value={data[name] || ""}
        label={label}
        placeholder={placeholder}
        disabled={disabled}
        //onFocus={onFocus}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  renderTextarea(
    name,
    label,
    placeholder,
    disabled = false,
    onFocus = false,
    type = "text",
    nrows = 5
  ) {
    const { data, errors } = this.state;

    return (
      <TextArea
        type={type}
        rows={nrows}
        name={name}
        value={data[name] || ""}
        label={label}
        placeholder={placeholder}
        disabled={disabled}
        onfocus={onFocus}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  renderInputCheckBox(id, name, label, link = "", linkUrl = "", linkTarget = "_parent", disabled = false) {
    const { data, errors } = this.state;
    return (
      <InputCheckBox
        id={id}
        name={name}
        value={data[name] || ""}
        checked={data[name]}
        label={label}
        disabled={disabled}
        onChange={this.handleChange}
        error={errors[name]}
        link={link}
        linkUrl={linkUrl}
        linkTarget={linkTarget}
      />
    );
  }
  renderInputCheckBoxArray(id, name, label, disabled = false) {
    const { data, errors } = this.state;
    return (
      <InputCheckBox
        id={id}
        name={name}
        value={data[name][id] || ""}
        checked={data[name][id] || false}
        label={label}
        disabled={disabled}
        onChange={this.handleChangeArray}
        error={errors[name]}
      />
    );
  }

  renderDateTime(name, label, placeholder, locale, dateFormat, minDate, disabled) {
    const { data, errors } = this.state;
    return (
      disabled == true ?
        <DateTime selected={data[name]} label={label} placeholderText={placeholder} locale={locale} dateFormat={dateFormat} minDate={minDate}
          onChange={date => this.handleDatePicker(name, date)} error={errors[name]} disabled />
        :
        <DateTime selected={data[name]} label={label} placeholderText={placeholder} locale={locale} dateFormat={dateFormat} minDate={minDate}
          onChange={date => this.handleDatePicker(name, date)} error={errors[name]} />
    )
  }

  // renderMacroPracticeType(
  //   name,
  //   label,
  //   list,
  //   disabled = false,
  //   onFocus = false
  // ) {
  //   const { data, errors } = this.state;
  //
  //   return (
  //     <MacroPracticeTypeComponent
  //       name={name}
  //       value={data[name]}
  //       label={label}
  //       list={list}
  //       disabled={disabled}
  //     />
  //   );
  // }

}

export default Form;
