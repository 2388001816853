import React, { Component } from "react";
import { standardMessage } from "../../_constants/";
import { cicassService } from "../../_services";
import Joi from "joi-browser";

const apiUrl = "ana_catastalcategory";


class CatastalCategoryComponent extends Component {
  // Domanda x Igor: a cosa serve?
  constructor(props) {
    super(props);
  }
  state = {
    editRow: { id: -1, fg: "", mapp: "", sub: "", ana_catastalcategory_description: "", ana_catastalcategory_id: -1, rc_value: 0, second_box: false },
    name: this.props.name,
    label: this.props.label,
    anacat: this.props.datalist,
    catastalCategories: [],
    errors: {},
    addOperation: 'ADD'
  };


  schema = {
    id: Joi.number(),
    fg: Joi.string(),
    mapp: Joi.string(),
    sub: Joi.string(),
    ana_catastalcategory_description: Joi.string(),
    ana_catastalcategory_id: Joi.number(),
    rc_value: Joi.number()
      .required()
      .label("R.C."),
    second_box: Joi.boolean(),
  };

  async componentDidMount() {
    //    ((console.log(this.state.anacat);
    //console.log("Immobili", this.props);
    const { data: catastalCategories } = await cicassService.getAll(apiUrl);
    this.setState({ catastalCategories });

  }

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.editRow, this.schema, options);
    //console.log(error);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    //console.log(errors);
    return errors;
  };
  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleLocalCopy = itemSelected => {
    this.setState({ editRow: this.copyRow(itemSelected) });
    if (itemSelected == null) this.setState({ addOperation: 'ADD' });
    else this.setState({ addOperation: 'MODIFY' });
  };

  handleLocalDelete = itemSelected => {
    this.props.onEdit(itemSelected, 'DELETE');
    this.handleLocalCopy(null);
  };

  handleLocalUpdate = myData => {
    //console.log(myData);
    let dataToSave = Object.assign({}, myData);
    var x = Number(dataToSave.ana_catastalcategory_id);
    //if (x != -1) { //potrebbero selezionare la categoria vuota
    if (x > 0) {
      const index = this.state.catastalCategories.findIndex(myRow => myRow.id == x);
      dataToSave.ana_catastalcategory_description = this.state.catastalCategories[index].name;
    }
    if (myData.id == - 1) dataToSave.id = this.props.maxId + 1;
    const addOperation = this.state.addOperation;
    this.props.onEdit(dataToSave, addOperation);
    this.handleLocalCopy(null);
  };

  handleChange = ({ target }) => {

    //console.log(target);

    let localCopy = JSON.parse(JSON.stringify(this.state.editRow))
    const value = target.type === "checkbox" ? target.checked : target.value;

    //console.log("a", value);

    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(target);
    if ((errorMessage && target.type != "checkbox") && !(target.value === "")) {
      errors[target.name] = errorMessage;
    }
    else {
      delete errors[target.name];
      //console.log(target.name);      
      localCopy[target.name] = value;
      //console.log(target.value);
      //console.log(value);
      this.setState({ editRow: localCopy })
    }

  };


  copyRow(data) {
    if (data == null) return { id: -1, fg: '', mapp: '', sub: '', ana_catastalcategory_description: '', ana_catastalcategory_id: -1, rc_value: 0, second_box: false };
    return {
      id: data.id,
      fg: data.fg,
      mapp: data.mapp,
      sub: data.sub,
      ana_catastalcategory_description: data.ana_catastalcategory_description,
      ana_catastalcategory_id: data.ana_catastalcategory_id,
      rc_value: data.rc_value,
      second_box: data.second_box
    };
  }

  render() {
    const { editRow, errors, anacat, name, label } = this.state;
    return (
      <div className="form-group m-4">
        <label htmlFor={name} className="font-weight-bold" >{label}</label>
        <table className="table mx-auto table-hover justify-content-center shadow p-3 mb-5 bg-white rounded">
          <thead>
            <tr>
              <th className="d-none d-sm-table-cell">Fg.</th>
              <th className="d-none d-sm-table-cell">Mapp.</th>
              <th className="d-none d-sm-table-cell">Sub.</th>
              <th className="d-none d-sm-table-cell">Cat.Catastale</th>
              <th>Rendita Catastale</th>
              <th>Seconda Pertinenza</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.props.datalist && this.props.datalist.map(item => (
              <tr key={item.id}>
                <td className="d-none d-sm-table-cell">{item.fg}</td>
                <td className="d-none d-sm-table-cell">{item.mapp}</td>
                <td className="d-none d-sm-table-cell">{item.sub}</td>
                <td className="d-none d-sm-table-cell">{item.ana_catastalcategory_description}</td>
                <td>&euro; {item.rc_value}</td>
                <td>{item.second_box == true ? 'SI' : 'NO'}</td>
                <td>
                  <button className="btn btn-sm btn-outline-dark mr-2 mb-2" onClick={() => this.handleLocalCopy(item)}>{standardMessage.CMD_EDIT}</button>
                  <button className="btn btn-sm btn-outline-danger mr-2 mb-2" onClick={() => this.handleLocalDelete(item)}>{standardMessage.CMD_DELETE}</button>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr><td colSpan="7"></td></tr>
            <tr>
              <td className="d-none d-sm-table-cell">
                <input type="text" name="fg" placeholder="Fg." className="form-control" disabled={false} value={editRow.fg} onChange={this.handleChange} />
                {errors.fg && <div className="alert alert-danger">{errors.fg}</div>}
              </td>
              <td className="d-none d-sm-table-cell">
                <input type="text" name="mapp" placeholder="Mapp." className="form-control" disabled={editRow.disabled} value={editRow.mapp} onChange={this.handleChange} />
                {errors.mapp && <div className="alert alert-danger">{errors.mapp}</div>}
              </td>
              <td className="d-none d-sm-table-cell">
                <input type="text" name="sub" placeholder="Sub." className="form-control" disabled={editRow.disabled} value={editRow.sub} onChange={this.handleChange} />
                {errors.sub && <div className="alert alert-danger">{errors.sub}</div>}
              </td>
              <td className="d-none d-sm-table-cell">
                <select name="ana_catastalcategory_id" id="ana_catastalcategory_id" value={editRow.ana_catastalcategory_id} className="form-control" onChange={this.handleChange}>
                  <option value="" />
                  {this.state.catastalCategories.map(option => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
                {errors.ana_catastalcategory_id && <div className="alert alert-danger">{errors.ana_catastalcategory_id}</div>}
              </td>
              <td>
                <div className="input-group">
                  <div className="input-group-addon">EUR</div>
                  {//editRow.rc_value > 0 && 
                    <input type="number" inputMode="decimal" pattern="[0-9]*" name="rc_value" placeholder="Valore in euro" className="form-control" disabled={editRow.disabled} value={editRow.rc_value || ""} onChange={this.handleChange} />}
                  {//editRow.rc_value <= 0 && <input type="number" inputMode="decimal" pattern="[0-9]*" name="rc_value" placeholder="Valore in euro" className="form-control" disabled={editRow.disabled} onChange={this.handleChange} />
                  }
                  {errors.rc_value && <div className="alert alert-danger">{errors.rc_value}</div>}
                </div>
              </td>
              <td>
                <div className="form-group form-check m-2">
                  <input id="second_box" name="second_box" className="form-check-input" type="checkbox" onChange={this.handleChange} checked={editRow.second_box} />
                  <label htmlFor="second_box" className="form-check-label"></label>
                </div>
              </td>
              <td>
                <button className="btn btn-sm btn-outline-success mr-2 mb-2" disabled={editRow.disabled} onClick={() => this.handleLocalUpdate(editRow)}>{standardMessage.CMD_CONFIRM}</button>
                <button className="btn btn-sm btn-outline-danger mr-2 mb-2" disabled={editRow.disabled} onClick={() => this.handleLocalCopy(null)}>{standardMessage.CMD_RESET}</button>
              </td>
            </tr>
          </tfoot>
        </table>

      </div>
    );
  }
}
export default CatastalCategoryComponent;
