import React from "react";
import Joi from "joi-browser";
import Form from "../../_components/common/form";
import Modal from "../../_components/common/modal";
import { cicassService, auth } from "../../_services";
import { config, history } from "../../_helpers";
import { ToastContainer, toast } from "react-toastify";
import { standardMessage } from "../../_constants";
import spinner from "../../images/form-preloader.gif";

//const apiUrl = "ana_user/agencyvalue";
const apiAgencyUrl = "ana_user/agencyvalue";

class SettingListPage extends Form {
  state = {
    data: {
      agency_value: 0
    },
    errors: {},
    myData: [], //cicassService.getAll(apiUrl)    
    pagina: "Parametri",
    visibleAgency: true,
    loadingSave: false,
    disableButton: false,
    saveMessage: standardMessage.CMD_SAVE,
    modalQuestion: "",
  };

  schema = {
    agency_value: Joi.number()
      .required()
      .label("Onere Segnalazione"),
  };

  async componentDidMount() {
    /*const isExpired = auth.checkExpired();
    const { data: myData } = await cicassService.getAll(apiUrl);
    this.setState({ myData });*/
    const av = await cicassService.getAll(apiAgencyUrl);
    //if (av.data < 0) this.setState({ visibleAgency: false });

    if (av.data) this.setState({ visibleAgency: (av.data.agency_value_is_visible == 1) });
    this.setState({ data: this.mapInit(av.data) });
    //console.log(this.state.data.agency_value);
  }

  mapInit(av) {
    // console.log("mapinit", av.agency_value);
    return {
      agency_value: (Number(av.agency_value) > 0 ? Number(av.agency_value) : 0), //storno agenzia      
    };
  }

  handleModalMessage = idBtn => {
    switch (idBtn) {
      case "s": {
        this.setState({ modalQuestion: standardMessage.MODAL_QUESTION_SAVE });
        break;
      }
      default:
        break;
    }
  };

  handleModalConfirm = () => {
    switch (this.state.modalQuestion) {
      case standardMessage.MODAL_QUESTION_SAVE: {
        this.doSubmit();
        break;
      }
      default:
        break;
    }
  };

  doSubmit = async () => {
    this.setState({ loadingSave: true });
    this.setState({ disableButton: true });
    const dataToSave = {
      agency_value: Math.max(Number(this.state.data.agency_value), 0)
    };
    //const dbAgency = await cicassService.putArray(apiUrl, dataToSave);
    await toast.success(standardMessage.CMD_SAVE_OK);
    //this.props.history.push("/users");
    this.setState({ loadingSave: false });
    this.setState({ disableButton: false });
    //window.location = "/welcome";
    history.replace("/welcome");
  }


  render() {
    const { user } = this.props; // verificare sempre che non sia undefined
    const { length: count } = this.state.myData;
    const {
      pagina,
      myData,
      modalQuestion,
      loadingSave,
      saveMessage,
      disableButton
    } = this.state;


    //const { data: myData } = this.state.myData;
    //const { myData } = this.state.myData;

    /* if (user && auth.isRoleOK(auth.HighRole) && count === 0) return (<React.Fragment>
       <div className="container-fluid">
         <div className="text-center">
           <img className="img-fluid" src={spinner} alt="loading..." />
         </div>
       </div>
     </React.Fragment>)*/

    return (
      <div>

        <React.Fragment>
          <div className="container-fluid">
            <ToastContainer autoClose={config.autoCloseToast} />
            <div className="row m-4">
              <div className="col">
                <h3>{pagina}</h3>
              </div>
              <div className="float-right">
                {this.renderButtonLink(standardMessage.CMD_BACK, "/welcome")}
              </div>
            </div>
            <div className="row m-4">
              <div className="col">
                Inserire il valore in euro dell'onere segnalazione desiderato. Tale valore apparir&agrave; automaticamente in ogni prospetto spesa come valore suggerito.
              </div>
            </div>
            {/*user && auth.isRoleOK(auth.HighRole) &&
              <div className="row">
                <div className="col">
                  <table className="table mx-auto table-hover justify-content-center shadow p-3 mb-5 bg-white rounded">
                    <thead>
                      <tr>
                        <th scope="col" className="clickable">Descrizione</th>
                        <th scope="col" className="clickable">Valore</th>
                      </tr>
                    </thead>
                    <tbody>
                      {myData && myData.map((item, i) => {
                        return (
                          <React.Fragment>
                            <tr key={i}>
                              <td>{item.description}</td>
                              <td>{item.value}</td>
                            </tr>
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
                    */}
            {this.state.visibleAgency &&
              <div>
                <form onSubmit={this.handleSubmit}>
                  <div className="row m-4">
                    <div className={config.bstrp6col}>
                      {this.renderInput("agency_value", "Onere Segnalazione", "Valore in euro")
                      }
                    </div>
                  </div>
                </form>
              </div>
            }
            {this.renderButtonModal(saveMessage, "modalOp", true, loadingSave, disableButton, () => { return this.handleModalMessage("s"); }, "btn btn-outline-success ml-4 mr-4 mb-4")
            }

            <Modal modalId="modalOp" modalTitle={standardMessage.MODAL_TITLE_ATTENTION} modalBody={modalQuestion} onModalConfirm={this.handleModalConfirm} />
          </div>
        </React.Fragment>


      </div>
    );
  }
}
export { SettingListPage };
