import React, { Component } from "react";
import _ from "lodash";

class TableBody extends Component {
  renderCell = (item, column) => {
    if (column.isBool) return ( _.get(item, column.path) == 1 ? "SI" : "NO" );
    if (column.content) return column.content(item);
    if (column.date) {
      // ritorna una data nello stile dd/mm/yyyy, hh:mm:ss
      return new Date(Date.parse(_.get(item, column.path))).toLocaleString(
        "it-IT"
      );
    }

    return _.get(item, column.path);
  };

  createKey = (item, column) => {
    return item._id + (column.path || column.key);
  };

  render() {
    const { data, columns } = this.props;

    return (
      <tbody>
        {data.map(item => (
          <tr key={item.id}>
            {columns.map(column => (
              <td
                key={this.createKey(item, column)}
                className={"" + (column.invisible && "d-none d-sm-table-cell")}
              >
                {this.renderCell(item, column)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  }
}

export default TableBody;
