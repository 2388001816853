import React from "react";
import { Link } from "react-router-dom";
import AgencylistTable from "../../../_components/AgencySubRefillListTable";
import Pagination from "../../../_components/common/pagination";
import SearchBox from "../../../_components/common/searchBox";
import Form from "../../../_components/common/form";
import { paginate } from "../../../utils/paginate";
import { cicassService, auth } from "../../../_services";
import { config, history } from "../../../_helpers";
import { ToastContainer, toast } from "react-toastify";
import spinner from "../../../images/form-preloader.gif";
import { standardMessage } from "../../../_constants/";
import _ from "lodash";

const apiUrl = "ana_subscription_refill/agency";

class AgencySubRefillList extends Form {
  state = {
    myData: [], //cicassService.getAll(apiUrl)
    choices: [],
    pageSize: config.pageSize,
    currentPage: 1,
    searchQuery: "",
    selectedChoice: null,
    sortColumn: { path: "title", order: "asc" },
    pagina: "Storico Refill: ",
    agencyData: {}
  };

  async componentDidMount() {
    try {
      const isExpired = auth.checkExpired();
      const dataId = this.props.match.params.id;
      const choices = [{ id: "", name: "Refill" }];
      //this.setState({ data: this.mapToViewModel(data) });
      if (Number(dataId) === -1) return;
      const { data: agencyData } = await cicassService.getById("ana_agency", dataId);
      const { data: myData } = await cicassService.getById(apiUrl, dataId);
      if (myData.length > 0) {

        const gruppo = myData.map(myData => {
          choices.push({ id: myData.name, name: myData.name });
        });
      }
      this.setState({ myData, choices, agencyData });
      //console.log("1", myData.length);
    } catch (ex) {
      console.log(ex);
    }

  }

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleChoiceSelect = choice => {
    this.setState({ selectedChoice: choice, searchQuery: "", currentPage: 1 });
  };

  handleSearch = query => {
    this.setState({ searchQuery: query, selectedChoice: null, currentPage: 1 });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const { pageSize, currentPage, sortColumn, selectedChoice, searchQuery, myData: allData } = this.state;

    let filtered = allData.filter(m => m.name !== "Cicass");
    if (searchQuery)
      filtered = allData.filter(m =>
        m.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    else if (selectedChoice && selectedChoice.id)
      filtered = allData.filter(m => m.name === selectedChoice.id);

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    const myData = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: myData };
  };

  render() {
    const { length: count } = this.state.myData;
    const { pageSize, currentPage, sortColumn, pagina, searchQuery, agencyData } = this.state;
    const dataId = this.props.match.params.id;
    const backLink = "/agencies/" + dataId;


    const { totalCount, data: myData } = this.getPagedData();

    return (
      <div className="container-fluid">
        <ToastContainer autoClose={config.autoCloseToast} />
        <div className="row m-4"><div className="col"><h3>{pagina + agencyData.name}</h3></div>
          <div className="float-right">
            <Link className="btn btn-outline-dark" to={backLink}>
              {standardMessage.CMD_BACK}
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {totalCount === 0 && (
              <p>
                Non sono stati trovati refill con queste condizioni di ricerca.
              </p>
            )}
            {totalCount === 1 && <p>E' stata trovato un solo refill.</p>}
            {totalCount > 1 && <p>Sono stati trovati {totalCount} refill per questa agenzia.</p>}
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
            <AgencylistTable
              myData={myData}
              sortColumn={sortColumn}
              onSort={this.handleSort}
            />
            <Pagination
              key={totalCount}
              itemsCount={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

export { AgencySubRefillList };
