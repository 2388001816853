import React from "react";

const SelectGroup = ({
  items,
  textProperty,
  valueProperty,
  onItemSelect
}) => {
  return (
    <select className="form-control" onChange={(e) => onItemSelect(e.target.options[e.target.selectedIndex].text, e.target.value)}>
      {items.map(item => (
        <option
          key={item[valueProperty]}
          value={item[valueProperty]}
        >
          {item[textProperty]}
        </option>
      ))}
    </select>
  );
};

SelectGroup.defaultProps = {
  textProperty: "name",
  valueProperty: "id"
};

export default SelectGroup;
