import React from "react";
import { Link } from "react-router-dom";
import Joi from "joi-browser";
import Form from "../../../_components/common/form";
import MacroPracticeTypeComponent from "../../../_components/common/macroPracticeType";
import Modal from "../../../_components/common/modal";
import { cicassService, auth } from "../../../_services";
import { config, history } from "../../../_helpers";
import { ToastContainer, toast } from "react-toastify";
import { standardMessage } from "../../../_constants";
import Moment from 'moment';

const apiUrlUser = "ana_user/combo";
const apiUrlGroup = "ana_group";
const apiUrlAgency = "ana_agency";
const apiUrlSubsription = "ana_subscription";
const apiUrlSubsriptionRefill = "ana_subscription_refill";
const apiUrlLogin = "ana_login";
const apiUrlMacroPracticeType = "v_user_macro_practicetype_value/user/";
const apiUrlSaveAccordi = "user_macro_practicetype_value/array/";
const apiUrlStagger = "setting/stagger";

class UserDetailPage extends Form {
  _isMounted = false;
  state = {
    data: {
      id: -1,
      username: "",
      //  password: "",
      mobile: "",
      name: "",
      surname: "",
      active: 0,
      admin: 0,
      anaAgencyId: 0,
      anaGroupId: 0,
      anaLoginId: -1,
      agencyValue: 0,
      agencyValueIsVisible: 0,
      isAfa: 1,
      isMandate: 0,
      isPractice: 0,
      anaSubscriptionId: 1,
      refillValue: 0,
      refillValueContract: 0, //add months to yearly subscription       
      assignee_id: 0, //Referente AFA
      ana_subscription_date: new Date(),
      extraScaglione: 0
    },
    agencies: [],
    groups: [],
    actives: [],
    isAfas: [],
    extraScagliones: [],
    isMandates: [],
    isPractices: [],
    admins: [],
    delegateds: [],
    agencyValueIsVisibles: [],
    macroPracticeType: [],
    anaSubscriptions: null,
    errors: {},
    disableInput: false,
    loadingSave: false,
    loadingDelete: false,
    loadingReset: false,
    disableButton: false,
    saveMessage: standardMessage.CMD_NEW,
    modalQuestion: ""
    //deleteQuestion: "Confermi l'eliminazione?"
  };
  // Link documentazione: https://github.com/hapijs/joi/blob/v10.6.0/API.md
  schema = {
    id: Joi.number(),
    username: Joi.string()
      .required()
      .email()
      .label("Username"),
    /* password: Joi.string()
      .required()
      .max(100)
      .label("Password"),*/
      
    mobile: Joi.string().required().max(20).label("Cellulare"),
    name: Joi.string().required().max(100).label("Nome"),
    surname: Joi.string().required().max(100).label("Cognome"),
    anaAgencyId: Joi.number().required().label("Agenzia"),
    anaGroupId: Joi.number().required().label("Gruppo"),
    anaLoginId: Joi.number().required().label("Login"),
    active: Joi.number().required().label("Attivo"),
    admin: Joi.number().required().label("Amministratore"),
    agencyValue: Joi.number().required().max(1500).label("Onere Segnalazione"),
    agencyValueIsVisible: Joi.number().required().label("Onere Segnalazione Visibile"),
    isAfa: Joi.number().required().label("Afasolving"),
    isMandate: Joi.number().required().label("Attività"),
    isPractice: Joi.number().required().label("Pratiche"),
    anaSubscriptionId: Joi.number().required().label("Abbonamento"),
    refillValue: Joi.number().label("Incarichi in regalo"),
    refillValueContract: Joi.number().label("Refill Contratto (in mesi)"),
    ana_subscription_date: Joi.alternatives().when('anaSubscriptionId', {
      is: Joi.valid(17,18,19,20), //only if affiliato
      then: Joi.date().required(),
      otherwise: Joi.date().allow(null)
    }).label("Data inizio Contratto"),
    assignee_id: Joi.number().required().label("Referente AFA"),
    extraScaglione: Joi.number().required().label("Scaglione Extra")
  };

  async componentDidMount() {
    this._isMounted = true;
    const isExpired = auth.checkExpired();
    // Carico tutti i dati da SERVIZI
    const yesNo = cicassService.getYesNo();
    const { data: agencies } = await cicassService.getAll(apiUrlAgency);
    const { data: anaSubscriptions } = await cicassService.getAll(apiUrlSubsription);
    const { data: macroPracticeType } = await cicassService.getAll(apiUrlMacroPracticeType + this.props.match.params.id);
    const ag = await cicassService.getAll(apiUrlGroup);
    const defAgency = await cicassService.getDefault(apiUrlAgency);
    const defSubscription = await cicassService.getDefault(apiUrlSubsription);
    const { data: stagger } = await cicassService.getAll(apiUrlStagger);

    const { data: arrayUser } = await cicassService.getAll(apiUrlUser);

    const dataId = this.props.match.params.id;
    if (this._isMounted) {
      const groups = ag.data.filter(m => m.name !== "SuperUser");
      let delegateds = [{ id: 0, name: '', loginId: -1, groupId: 2 }];
      delegateds.push(...arrayUser.filter(x => x.groupId == 2));

      //console.log("delegateds", delegateds);
      if (macroPracticeType[0].ana_user_id === -1) {
        for (var i = 0; i < macroPracticeType.length; i++) {
          macroPracticeType[i].ana_user_id = Number(this.props.match.params.id);
        }
      }

      //aggiorno gli scaglioni
      for (var i = 0; i < macroPracticeType.length; i++) {
        for (var j = 0; j < 5; j++) {
          macroPracticeType[i]['stagger_0' + j] = stagger.find(x => x.parameter == ("stagger_0" + j)) ? Number(stagger.find(x => x.parameter == ("stagger_0" + j)).value) : 0;
          //console.log("scagliuni ", j, " valore: ", stagger.find(x => x.parameter == ("stagger_0" + j)).value);
        }
      }

      this.setState({ agencies, anaSubscriptions, groups, macroPracticeType, actives: yesNo, extraScagliones: yesNo, isAfas: yesNo, isMandates: yesNo, isPractices: yesNo, admins: yesNo, agencyValueIsVisibles: yesNo, delegateds });
      this.setState(state => {
        return (this.state.data.anaAgencyId = defAgency.data.id);
      });
      this.setState(state => {
        return (this.state.data.anaSubscriptionId = defSubscription.data.id);
      });
      if (Number(dataId) === -1) return;
      else {
        const disableInput = true;
        this.setState({ disableInput, saveMessage: standardMessage.CMD_EDIT });
      }
      // Non lo è faccio la GET gestisco errore o mapping
      const { data } = await cicassService.getById("v_user_page", dataId);
      if (data.id === 0) {
        history.replace("/not-found");
      }
      // data.refill_value_contract = 2;

      this.setState({ data: this.mapToViewModel(data) });
      //console.log(this.state.data);
    }

  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  mapToViewModel(data) {
    // devo mappare i miei dati
    return {
      id: data.id,
      username: data.username,
      mobile: data.mobile,
      //password: "xxxxxxxx", //data.password,
      name: data.name,
      surname: data.surname,
      active: data.active,
      admin: data.admin,
      anaAgencyId: data.ana_agency_id,
      anaGroupId: data.ana_group_id,
      anaLoginId: data.ana_login_id,
      agencyValue: data.agency_value,
      agencyValueIsVisible: data.agency_value_is_visible,
      isAfa: data.isAfa,
      isMandate: data.isMandate,
      isPractice: data.isPractice,
      anaSubscriptionId: data.ana_subscription_id,
      refillValue: data.refill_value,
      refillValueContract: data.refill_value_contract,      
      ana_subscription_date: (data.ana_subscription_date != null ? new Date(Moment(data.ana_subscription_date).format("YYYY/MM/DD")) : null),
      assignee_id: data.assignee_id,
      extraScaglione: data.extraScaglione
    };
  }

  handleModalMessage = idBtn => {
    switch (idBtn) {
      case "d": {
        this.setState({ modalQuestion: standardMessage.MODAL_QUESTION_DELETE });
        break;
      }
      case "s": {
        this.setState({ modalQuestion: standardMessage.MODAL_QUESTION_SAVE });
        break;
      }
      case "r": {
        this.setState({
          modalQuestion: standardMessage.MODAL_QUESTION_RESET_PASSWORD
        });
        break;
      }
      default:
        break;
    }
  };

  handleModalConfirm = () => {
    switch (this.state.modalQuestion) {
      case standardMessage.MODAL_QUESTION_DELETE: {
        this.handleDelete();
        break;
      }
      case standardMessage.MODAL_QUESTION_SAVE: {
        this.doSubmit();
        break;
      }
      case standardMessage.MODAL_QUESTION_RESET_PASSWORD: {
        this.handleResetPassword();
        break;
      }
      default:
        break;
    }
  };

  handleAccordi = (singleAccordo) => {
    // console.log(singleAccordo);
    const macroPracticeType = [...this.state.macroPracticeType];
    //const index = macroPracticeType.findIndex(myRow => myRow.id == singleAccordo.id);
    //console.log("riga", singleAccordo.);
    const index = macroPracticeType.findIndex(myRow => myRow.ana_macro_practicetype_id === singleAccordo.ana_macro_practicetype_id);
    macroPracticeType[index].min_value = Number(singleAccordo.min_value);
    macroPracticeType[index].max_value = Number(singleAccordo.max_value);
    this.setState({ macroPracticeType });
  };

  handleResetPassword = async () => {
    this.setState({ loadingReset: true, disableButton: true });
    try {
      /*await cicassService.getWithCustomUrl(
        apiUrlLogin + "/resetpassword/" + this.state.data.anaLoginId
      );*/
      await auth.resetPassword(this.state.data.anaLoginId);
      toast.success(standardMessage.CMD_RESET_PWD_OK);
      this.setState({ loadingReset: false });
      window.location = "/users";
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error(standardMessage.CMD_RESET_PWD_KO);
      this.setState({ loadingReset: false });
    }
    this.setState({ disableButton: false });
  };

  handleDelete = async () => {
    this.setState({ loadingDelete: true });
    this.setState({ disableButton: true });
    try {
      await cicassService.deleteOp(apiUrlLogin, this.state.data.anaLoginId);
      toast.success(standardMessage.CMD_DELETE_OK);
      this.setState({ loadingDelete: false });
      window.location = "/users";
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error(standardMessage.CMD_DELETE_KO);
      this.setState({ loadingDelete: false });
    }
    this.setState({ disableButton: false });
  };

  doSubmit = async () => {
    this.setState({ loadingSave: true, disableButton: true });
    // SALVO in ANA_LOGIN
    //manage notary group
    let _isafa = (this.state.data.anaGroupId != 4) ? Number(this.state.data.isAfa) : 0;
    let _ismandate = (this.state.data.anaGroupId != 4) ? Number(this.state.data.isMandate) : 0;
    let _ispractice = (this.state.data.anaGroupId != 4) ? Number(this.state.data.isPractice) : 1;
    let _extraScaglione = Number(this.state.data.extraScaglione);
    // isafa: false, ismandate true, ispractice true
    const dataToSave = {
      id: Number(this.state.data.anaLoginId),
      ana_login_id: Number(this.state.data.anaLoginId),
      username: this.state.data.username,
      mobile: this.state.data.mobile,
      //password: this.state.data.password,
      active: Number(this.state.data.active),
      admin: Number(this.state.data.admin),
      ana_user_id: Number(this.state.data.id),
      name: this.state.data.name,
      surname: this.state.data.surname,
      ana_agency_id: Number(this.state.data.anaAgencyId),
      ana_group_id: Number(this.state.data.anaGroupId),
      agency_value: Number(this.state.data.agencyValue),
      agency_value_is_visible: Number(this.state.data.agencyValueIsVisible),
      isAfa: _isafa,//Number(this.state.data.isAfa),
      isMandate: _ismandate,//Number(this.state.data.isMandate),
      isPractice: _ispractice,//Number(this.state.data.isPractice),
      ana_subscription_id: Number(this.state.data.anaSubscriptionId),
      refill_value: Number(this.state.data.refillValue),
      refill_value_contract: Number(this.state.data.refillValueContract),
      ana_subscription_date: Moment(this.state.data.ana_subscription_date).add(2, 'hours'),
      assignee_id: Number(this.state.data.anaGroupId) == 3 ? Number(this.state.data.assignee_id) : 0,
      extraScaglione: _extraScaglione
    };
    //console.log(dataToSave);
    let dbAnaUser = null;
    if (dataToSave.ana_user_id != -1) dbAnaUser = await auth.update(dataToSave);
    else dbAnaUser = await auth.create(dataToSave);


    // SALVO IMPOSTAZIONI ACCORDI
    if (this.state.data.anaGroupId != 4) { //se non è notaio 
      if (this.state.macroPracticeType[0].id < 0) {
        const insertAccordi = await cicassService.postArray(apiUrlSaveAccordi + dbAnaUser.data.id, this.state.macroPracticeType);
      }
      else {
        // console.log(this.state.macroPracticeType);
        const updateAccordi = await cicassService.putArray(apiUrlSaveAccordi + dbAnaUser.data.id, this.state.macroPracticeType);
      }
    }
    toast.success(standardMessage.CMD_SAVE_OK);
    //this.props.history.push("/users");
    this.setState({ loadingSave: false, disableButton: false });
    window.location = "/users";
  };

  /* handleNotary = ({ currentTarget: input }) => {
 
     const value = input.value;
     const data = { ...this.state.data };
     data[input.name] = value;
     console.log(value);
     this.setState({ data });
   };*/

  handleChangeContract = ({ currentTarget: input }) => {
    const value = input.type === "checkbox" ? input.checked : input.value;
    const errors = { ...this.state.errors };
    if (!(input.type === "checkbox")) {
      const errorMessage = this.validateProperty(input);
      if (errorMessage) errors[input.name] = errorMessage;
      else delete errors[input.name];
    }
    const data = { ...this.state.data };
    data[input.name] = value;
    if (input.value != 1) { //abbonamenti
      data.isAfa = 0;
      data.isMandate = 1;
      data.isPractice = 1;
      data.active = 1;
    }
    else { //archivi
      //data.isAfa = 0;
      //data.isMandate = 0;
      //data.isPractice = 0;
      data.active = 0;
    }
    this.setState({ data, errors });
  };

  render() {
    const { loadingSave, loadingDelete, loadingReset, saveMessage, disableButton, modalQuestion, anaSubscriptions, data, macroPracticeType } = this.state;
    const dataId = this.props.match.params.id;
    const refillLink = "/subrefilluser/" + dataId;
    const contractrefillLink = "/contractrefilluser/" + dataId;
    return (
      <div className="container-fluid">
        <ToastContainer autoClose={config.autoCloseToast} />
        <div className="row m-4">
          <div className="col"><h1>Utente</h1></div>
          <div className="float-right">{this.renderButtonLink(standardMessage.CMD_BACK, "/users")}</div>
        </div>
        <div>
          <form onSubmit={this.handleSubmit}>
            <div className="row m-2">
              <div className={config.bstrp6col}>{this.renderInput("username", "Username", "Indirizzo Mail Utente", "email", this.state.disableInput)}</div>
              <div className={config.bstrp6col}> {this.renderInput("mobile", "Cellulare", "Cellulare Utente")}</div>
              <div className={config.bstrp3col}>{this.renderInput("name", "Nome", "Nome Utente")}</div>
              <div className={config.bstrp3col}> {this.renderInput("surname", "Cognome", "Cognome Utente")}</div>
              
            </div>
            <div className="row m-2">
              <div className={config.bstrp6col}>{this.renderSelect("anaGroupId", "Gruppo", this.state.groups)}</div>
              <div className={config.bstrp6col}>{this.renderSelectWithId("active", "Attivo", this.state.actives)}</div>
              {anaSubscriptions != null && this.state.data.anaGroupId == 3 && //only Affiliati
                    (<React.Fragment>
                      <div className={config.bstrp6col}>{this.renderSelectWithId("anaSubscriptionId", "Contratto", anaSubscriptions, this.handleChangeContract)}</div>
                      {this.state.data.anaSubscriptionId != "1" &&
                        <>
                          <div className={config.bstrp6col}>{this.renderDateTime("ana_subscription_date", "Inizio Contratto", "Data Inizio Contratto", "it", "dd/MM/yyyy")}</div>
                          <div className={config.bstrp6col}>{this.renderInput("refillValueContract", "Refill Contratto (in mesi)", "Refill Contratto")}</div>
                  <div className={config.bstrp6col}>{this.renderButtonLinkBottom(standardMessage.CMD_HISTORY_CONTRACT, contractrefillLink, "btn btn-outline-dark")}</div>
                        </>
                      }
                    </React.Fragment>
                    )
                  }
              
            </div>
            <div className='row m-2'>
              {this.state.data.anaGroupId != 4 && //notaio
                <>
                  <div className={config.bstrp6col}>{this.renderSelectWithId("isAfa", "Afasolving", this.state.isAfas)}</div>
                  <div className={config.bstrp6col}>{this.renderSelectWithId("isPractice", "Pratiche", this.state.isPractices)}</div>
                  <div className={config.bstrp6col}>{this.renderSelectWithId("isMandate", "Attività", this.state.isMandates)}</div>
                  <div className={config.bstrp6col}>{this.renderInput("refillValue", "Incarichi in regalo", "Incarichi in regalo")}</div>
                  <div className={config.bstrp6col}>{this.renderButtonLinkBottom(standardMessage.CMD_HISTORY, refillLink, "btn btn-outline-dark")}</div>
                </>
              }
            </div>
            {this.state.data.anaGroupId != 4 && //notaio
              <>
                {data.isAfa == 1 && (
                  <div className="row m-2">
                    <div className={config.bstrp6col}>{this.renderSelectWithId("anaAgencyId", "Agenzia", this.state.agencies)}</div>
                    {//per il discorso dell'Amministratore c'è da verificare se è il caso di tenerlo o meno!
                      <div className={config.bstrp6col}> {this.renderSelectWithId("admin", "Capo Agenzia", this.state.admins)}</div>
                    }
                    <div className={config.bstrp6col}>{this.renderSelectWithId("agencyValueIsVisible", "Onere Segnalazione Visibile", this.state.agencyValueIsVisibles)}</div>
                    <div className={config.bstrp6col}>{this.renderInput("agencyValue", "Onere Segnalazione", "Valore in euro")}</div>

                  </div>
                )
                }
                {this.state.data.anaGroupId == 3 && //referente AFA solo per affiliati
                  <div className="row m-2">
                    <>
                      <div className={config.bstrp6col}>{this.renderSelectWithId("assignee_id", "Referente AFA", this.state.delegateds)}</div>
                    </>
                  </div>}
                
                {
                  //scaglione 0-149000mila euro
                   
                }

                
                {this.state.macroPracticeType.length > 1 && data.isAfa == 1 &&
                  <div>                    
                      <div className="row m-2">
                        <div className={config.bstrp6col}>{this.renderSelectWithId("extraScaglione", "Scaglione Extra", this.state.extraScagliones)}</div>
                      </div>
                      <MacroPracticeTypeComponent name="macroPracticeType" label="Accordi" datalist={this.state.macroPracticeType} onEdit={this.handleAccordi} extraScaglione={this.state.data.extraScaglione} />
                  </div>
                  }
              </>
              //notaio
            }
            {this.renderButtonModal(saveMessage, "modalOp", true, loadingSave, disableButton, () => { return this.handleModalMessage("s"); }, "btn btn-outline-success ml-4 mr-4 mb-4")}
            {dataId > -1 &&
              this.renderButtonModal(standardMessage.CMD_DELETE, "modalOp", true, loadingDelete, disableButton, () => { return this.handleModalMessage("d"); }, "btn btn-outline-danger mr-4 mb-4")}
            {dataId > -1 &&
              this.renderButtonModal(standardMessage.CMD_RESET_PWD, "modalOp", true, loadingReset, disableButton, () => { return this.handleModalMessage("r"); }, "btn btn-outline-warning mr-4 mb-4")}
            {dataId > -1 && data.isAfa == 1 &&
              (<Link className="btn btn-outline-dark mb-4" to={`/users/practice/${dataId}`}>{standardMessage.CMD_PRACTICE}</Link>)}

          </form>
        </div>
        <Modal modalId="modalOp" modalTitle={standardMessage.MODAL_TITLE_ATTENTION} modalBody={modalQuestion} onModalConfirm={this.handleModalConfirm} />
      </div>
    );
  }
}

export { UserDetailPage };
