import React from "react";
import { Route, Redirect } from "react-router-dom";
import { auth } from "../_services/authService";

export const PrivateRoute = ({
  path,
  component: Component,
  render,
  ...rest
}) => {
  const user = auth.getCurrentUser();

  return (
    <Route
      {...rest}
      render={props => {
        if (!user)
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          );

        if (rest.policy && !auth.isRoleOK(rest.policy, user))
          return (
            <Redirect
              to={{
                pathname: "/welcome",
                state: { from: props.location }
              }}
            />
          );

        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};
