import React from "react";
import Joi from "joi-browser";
import Form from "../../_components/common/form";
import { cicassService } from "../../_services";
import { config } from "../../_helpers";
import { ToastContainer, toast } from "react-toastify";
import { standardMessage } from "../../_constants/";

const apiUrl = "email";

class ContactPage extends Form {
  state = {
    data: {
      id: -1,
      name: "",
      phone: "",
      emailFrom: "",
      emailTo: config.emailAfa,
      message: "",
      type: "PUBBLICO"
    },
    errors: {},
    loading: false
  };

  schema = {
    id: Joi.number(),
    name: Joi.string()
      .required()
      .max(100)
      .label("Nome completo"),
    phone: Joi.string()
      .required()
      .max(100)
      .label("Numero di telefono"),
    emailFrom: Joi.string()
      .required()
      .email()
      .label("Email"),
    emailTo: Joi.string()
      .required()
      .email(),
    message: Joi.string()
      .required()
      .label("Messaggio"),
    type: Joi.string().required()
  };

  doSubmit = async () => {
    //console.log("qui ci entro. contactus");
    this.setState({ loading: true });
    // SALVO in EMAIL
    const dataToSave = {
      id: Number(this.state.data.id),
      name: this.state.data.name,
      phone: this.state.data.phone,
      emailFrom: this.state.data.emailFrom,
      emailTo: config.emailAfa,
      message: this.state.data.message,
      type: "PUBBLICO"
    };
    const dbEmail = await cicassService.saveOp(apiUrl, dataToSave);
    toast.success(standardMessage.MSG_SENT_OK);
    //this.props.history.push("/users");
    this.setState({ loading: false });
    //window.location = "/contactus";
  };

  render() {
    const { loading } = this.state;
    return (
      <React.Fragment>
        <div className="container">
          <ToastContainer autoClose={config.autoCloseToast} />
          <h1 className="mt-4 mb-3">Contatti </h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item active">Contatti</li>
          </ol>
          <div className="row">
            <div className="col-lg-8 mb-4">
              <iframe height="400" width="100%" frameBorder="0" src="https://www.bing.com/maps/embed?h=400&w=610&cp=45.51462634368229~9.204601489410384&lvl=17&typ=d&sty=r&src=SHELL&FORM=MBEDV8" scrolling="no">
              </iframe>
              <div >
                <a id="largeMapLink" target="_blank" href="https://www.bing.com/maps?cp=45.51462634368229~9.204601489410384&amp;sty=r&amp;lvl=17&amp;FORM=MBEDLD">Visualizza mappa più grande</a> &nbsp; | &nbsp;
                <a id="dirMapLink" target="_blank" href="https://www.bing.com/maps/directions?cp=45.51462634368229~9.204601489410384&amp;sty=r&amp;lvl=17&amp;rtp=~pos.45.51462634368229_9.204601489410384____&amp;FORM=MBEDLD">Ottieni indicazioni stradali</a>
              </div>
            </div>
            <div className="col-lg-4 mb-4" itemScope itemType="http://schema.org/LocalBusiness">
              <h3 itemProp="name">AFA solving s.r.l.</h3>
              <p>
                Viale Rodi, 85 (Zona Bicocca)
                <br />
                20126 Milano
                <br />
              </p>
              <p>
                <abbr title="Phone">T</abbr>:&nbsp;
                <span itemProp="telephone">
                  <a href="tel:+390284107431">
                    +39 02 8410 7431
                  </a>
                </span>
              </p>
              <p>
                <abbr title="Mobile">M (1)</abbr>:&nbsp;
                <span itemProp="telephone">
                  <a href="tel:+393703394019">
                    +39 370 339 4019
                  </a>
                </span>

              </p>
              <p>
                <abbr title="Mobile">M (2)</abbr>:&nbsp;
                <span itemProp="telephone">
                  <a href="tel:+393516157657">
                    +39 351 615 7657
                  </a>
                </span>

              </p>
              <p>
                <abbr title="Email">E</abbr>:&nbsp;
                <a href="mailto:afasolving@hotmail.com">
                  afasolving@hotmail.com
                </a>
              </p>
              <p>
                <abbr title="Hours">H</abbr>: Luned&igrave; - Venerd&igrave;:
                9:30 - 12:30 | 15:00 - 19:00
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-8 mb-4">
              <h3>Scrivici un messaggio</h3>
              <form onSubmit={this.handleSubmit}>
                {this.renderInput("name", "Nome", "Nome completo")}
                {this.renderInput("phone", "Telefono", "Numero di telefono")}
                {this.renderInput(
                  "emailFrom",
                  "Email",
                  "Indirizzo email",
                  "email"
                )}
                {this.renderTextarea(
                  "message",
                  "Messaggio",
                  "Scrivici un messaggio o chiedici qualcosa"
                )}

                {this.renderButton("Invia", false, loading)}
                {/* creo il bottone login, che NON è di tipo VALIDAZIONE, e quando è in loading mostra lo spinner sul bottone */}
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export { ContactPage };
