import React, { Component } from "react";

class Parag extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { title } = this.props;

    return (
      <div className="m-3">
        <h4>{title}</h4>
        {this.props.children}
      </div>
    );
  }
}

export default Parag;
