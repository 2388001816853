import React, { Component } from "react";
import Table from "./common/table";

class MandateRefillListTable extends Component {
  columns = [
    { path: "createdAt", label: "Data", date: true },
    { path: "refill_value", label: "Incarichi in regalo" }

  ];

  render() {
    const { myData, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={myData}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default MandateRefillListTable;
