import React from "react";
import Joi from "joi-browser";
import Form from "../../_components/common/form";
import ModalNew from "./modalnew";
import VoicelistTable from "../../_components/VoicelistTable";
import { cicassService, auth } from "../../_services";
import { config, history } from "../../_helpers";
import { ToastContainer, toast } from "react-toastify";
import { paginate } from "../../utils/paginate";
import Pagination from "../../_components/common/pagination";
import SearchBox from "../../_components/common/searchBox";
import { standardMessage } from "../../_constants";
import spinner from "../../images/form-preloader.gif";
import _ from "lodash";

const apiUrl = "voice";

class VoicePage extends Form {
  state = {
    data: {
      id: -1,
      name: "",
      value: "",
    },
    errors: {},
    myData: [],
    pageSize: config.pageSize,
    currentPage: 1,
    searchQuery: "",
    sortColumn: { path: "title", order: "asc" },
    pagina: "Voci Excel",
    plusMessage: "+",
    modalQuestion: "",
  };

  schema = {
    id: Joi.number(),
    name: Joi.string()
      .required()
      .max(50)
      .label("Nome"),
    value: Joi.string()
      .required()
      .max(255)
      .label("Valore"),
  };

  async componentDidMount() {
    const isExpired = auth.checkExpired();
    const { data: myData } = await cicassService.getAll(apiUrl);
    // console.log(myData);
    this.setState({ myData });
  }

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSearch = query => {
    this.setState({ searchQuery: query, selectedChoice: null, currentPage: 1 });
  };

  handleEdit = singleElem => {
    // console.log("Modifica", singleElem);
    //history.push(`/users/${singleElem.id}`);
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      selectedChoice,
      searchQuery,
      myData: allData
    } = this.state;

    // let filtered = allData.filter(m => m.name !== "SuperUser");
    let filtered = allData;
    if (searchQuery)
      filtered = allData.filter(m =>
        m.value.toLowerCase().includes(searchQuery.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    const myData = paginate(sorted, currentPage, pageSize);
    return { totalCount: filtered.length, data: myData };
  };



  handleModalMessage = idBtn => {
    switch (idBtn) {
      case "s": {
        this.setState({ modalQuestion: standardMessage.MODAL_QUESTION_CREATE });
        break;
      }
      default:
        break;
    }
  };

  handleModalConfirm = () => {
    switch (this.state.modalQuestion) {
      case standardMessage.MODAL_QUESTION_CREATE: {
        this.doSubmit();
        break;
      }
      default:
        break;
    }
  };

  doSubmit = async () => {
    //console.log("creo voce");
    const dataToSave = {
      id: Number(this.state.data.id),
      name: this.state.data.name,
      value: Number(this.state.data.value)
    };
    const dbAna = await cicassService.saveOp(apiUrl, dataToSave);
    toast.success(standardMessage.CMD_SAVE_OK);
    this.setState({
      data: {
        id: -1,
        name: "",
        value: "",
      }
    });
    //console.log("fatto");
    window.location = "/voice";
  }


  render() {
    const { length: count } = this.state.myData;
    const {
      pageSize,
      currentPage,
      sortColumn,
      pagina,
      searchQuery,
      modalQuestion,
      loadingSave,
      plusMessage,
      disableButton
    } = this.state;

    if (count === 0) return (<React.Fragment>
      <div className="container-fluid">
        <div className="text-center">
          <img className="img-fluid" src={spinner} alt="loading..." />
        </div>
      </div>
    </React.Fragment>)

    const { totalCount, data: myData } = this.getPagedData();

    return (
      <div>

        <React.Fragment>
          <div className="container-fluid">
            <ToastContainer autoClose={config.autoCloseToast} />
            <div className="row m-4">
              <div className="col">
                <h3>{pagina}</h3>
              </div>
              <div className="float-right">

              </div>
              <div className="float-right">
                {this.renderButtonLink(standardMessage.CMD_BACK, "/welcome")}
              </div>
            </div>
            <div className="row">
              <div className="col">
                <p>Ci sono {totalCount} voci definite.</p>
                <SearchBox value={searchQuery} onChange={this.handleSearch} />
                <VoicelistTable
                  myData={myData}
                  sortColumn={sortColumn}
                  onDelete={this.handleDelete}
                  onEdit={this.handleEdit}
                  onSort={this.handleSort}
                />
                <Pagination
                  key={totalCount}
                  itemsCount={totalCount}
                  pageSize={pageSize}
                  currentPage={currentPage}
                  onPageChange={this.handlePageChange}
                />
              </div>
            </div>
            <div className="form-group mr-2">
              <div className="row m-2">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">{this.renderInput("name", "Nome", "Nome", "text", this.state.disableInput)}</div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">{this.renderInput("value", "Valore", "Valore")}</div>

                {this.renderButtonModal(plusMessage, "modalOp", true, loadingSave, disableButton, () => { return this.handleModalMessage("s"); }, "btn btn-outline-success mr-4 mb-4")
                }

                <ModalNew modalId="modalOp" modalTitle={standardMessage.MODAL_TITLE_ATTENTION} modalBody={modalQuestion} onModalConfirm={this.handleModalConfirm} />
              </div>
            </div>
          </div>
        </React.Fragment>


      </div>
    );
  }
}
export { VoicePage };
