import React, { Component } from "react";
import Table from "./common/table";

class LoginAttemptlistTable extends Component {
  columns = [
    { path: "email", label: "Email" },
    {
      path: "ipaddress",
      label: "Indirizzo IP",
      invisible: true
    },
    { path: "login_date", label: "Data", invisible: true, date: true },
    { path: "succeeded", label: "Esito" }
    /*  {
      key: "delete",
      content: choice => (
        <button
          onClick={() => this.props.onDelete(choice)} //this.props.handleDelete(row)
          className="btn btn-outline-danger"
        >
          Elimina
        </button>
      )
    },
    {
      key: "edit",
      content: choice => (
        <button
          onClick={() => this.props.onEdit(choice)}
          className="btn btn-outline-danger"
        >
          Delete
        </button>
      )
    }*/
  ];

  render() {
    const { myData, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={myData}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default LoginAttemptlistTable;
