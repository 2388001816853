import React, { Component } from "react";
import Table from "./common/table";

class VoicelistTable extends Component {
  columns = [
    { path: "id", label: "id" },
    { path: "value", label: "Valore" }
  ];

  render() {
    const { myData, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={myData}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default VoicelistTable;
