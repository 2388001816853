import React from "react";

const TextArea = ({
  name,
  label,
  placeholder,
  disabled,
  onfocus,
  error,
  ...rest
}) => {
  return (
    <div className="form-group">
      <label htmlFor={name} className="font-weight-bold">
        {label}
      </label>
      <textarea
        {...rest}
        name={name}
        id={name}
        placeholder={placeholder}
        className="form-control"
        disabled={disabled}
        autoFocus={onfocus}        
      />
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default TextArea;
