import React, { Component } from "react";
import Table from "./common/table";

class GrouplistTable extends Component {
  columns = [
    { path: "name", label: "Nome" },
    { path: "description", label: "Descrizione" }
  ];

  render() {
    const { myData, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={myData}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default GrouplistTable;
