import React from "react";
import { config, history } from "../../_helpers";
import Input from "../../_components/common/input";

const ModalNew = ({
    modalId,
    modalTitle,
    modalBody,
    onModalConfirm,
    btnOk = "Crea",
    btnClose = "Annulla"
}) => {
    return (
        <React.Fragment>
            <div
                className="modal fade"
                id={modalId}
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {"Crea nuova voce"}
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {modalBody}
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-outline-danger"
                                data-dismiss="modal"
                            >
                                {btnClose}
                            </button>
                            <button
                                type="button"
                                className="btn btn-outline-success"
                                onClick={onModalConfirm}
                                data-dismiss="modal"
                            >
                                {btnOk}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default ModalNew;
