import React from "react";
import { Link } from "react-router-dom";
import QuotationlistTable from "../../_components/QuotationlistTable";
import SelectGroup from "../../_components/common/selectGroup";
import Pagination from "../../_components/common/pagination";
import { paginate } from "../../utils/paginate";
import SearchBox from "../../_components/common/searchBox";
import Form from "../../_components/common/form";
import { cicassService, auth } from "../../_services";
import { config, history } from "../../_helpers";
import { ToastContainer, toast } from "react-toastify";
import spinner from "../../images/form-preloader.gif";
import { standardMessage } from "../../_constants/";
import _ from "lodash";
import ListGroup from "../../_components/common/listGroup";

const apiUrl = "v_quotation";
const apiDown = "upload/quotation";

const HowManyQuotations = (props) => {
  const {totalCount, selectedChoice2, nAccepted} = props;
 
  if (totalCount === 1) {
    return (<p>E' stato trovato un prospetto spesa{!selectedChoice2 || selectedChoice2.id == 1 ? ' accettato' : ''}.</p>);
  }
  if (props.totalCount > 1 && selectedChoice2.id == "") {
  return (<p>Sono stati trovati {totalCount} prospetti spesa, di cui {nAccepted} {nAccepted == 1 ? 'accettato' : 'accettati'}.</p>);
  }
  if (props.totalCount > 1 && selectedChoice2.id == "0") {
    return (<p>Sono stati trovati {totalCount} prospetti spesa.</p>);
  }
  if (props.totalCount > 1 && selectedChoice2.id == "1") {
    return (<p>Sono stati trovati {totalCount} prospetti spesa accettati.</p>);
    }
  return (
    <p>
      Non sono stati trovati prospetti spesa con queste condizioni di ricerca.
    </p>
  );
}

class QuotationListPage extends Form {
  constructor(props) {
    super(props);
    this.state = {
      myData: [], //cicassService.getAll(apiUrl)
      choices: [],
      quotationchoices: [{ id: "", name: "Tutti" }, { id: "1", name: "Accettati" }, { id: "0", name: "Non accettati" }],
      pageSize: config.pageSize,
      currentPage: 1,
      searchQuery: "",
      selectedChoice: null,
      selectedChoice2: null,
      sortColumn: { path: "title", order: "asc" },
      pagina: "Prospetti spesa",
      nAccepted: 0
    };
    this.handleAccept = this.handleAccept.bind(this);
  }
  async componentDidMount() {

    const isExpired = auth.checkExpired();
    const { data: myData } = await cicassService.getAll(apiUrl);
    if (this.props.user.role === "Affiliato") {
      var choices2 = [{ id: "", name: "Tipi" }];
      var gruppo = myData.map(myData => {
        choices2.push({ id: myData.ana_quotationtype_id, name: myData.type });
      });
    }
    else {
      var choices2 = [{ id: "", name: "Tutti gli affiliati" }];
      var gruppo = myData.map(myData => {
        choices2.push({ id: myData.ana_user_id, name: myData.owner });
      });
    }

    const choices = [];
    const map = new Map();
    for (const item of choices2) {
      if (!map.has(item.id)) {
        map.set(item.id, true); // set any value to Map
        //if (item.name !== "SuperUser") {
        choices.push({
          id: item.id,
          name: item.name
        });
        //}
      }
    }
    choices.sort(function (a, b) {
      if (a.name > b.name || b.name == "Tutti gli affiliati" || b.name == "Tipi") {
        return 1;
      }
      if (b.name > a.name) {
        return -1;
      }
      return 0;
    });
    this.handleChoiceSelect2({ id: "", name: "Tutti" });
    this.setState({ myData, choices, nAccepted: myData.filter((obj) => obj.isaccepted === 1).length });
  }

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };


  async handleDown(id, accountholder) {
    //console.log("Tentativo di download", id, accountholder.replace(/[^a-zA-Z0-9]/g, ''));
    await cicassService.downloadFile("quotation/" + id, "prospetto_spesa" + "_" + accountholder.replace(/[^a-zA-Z0-9]/g, '') + ".pdf");
  }

  async handleAccept(id, newchoice) {
    const { myData } = this.state;
    const dataToSave = {
      id: id,
      isaccepted: newchoice
    };
    await cicassService.saveOp("quotation/accept", dataToSave)
      .then(res => {
        for (let x = 0; x < myData.length; x++) {
          if (myData[x].id == id) myData[x].isaccepted = newchoice;
        }
        if (newchoice==1) toast.success("Prospetto spesa accettato.");
        this.setState({ myData, nAccepted: myData.filter((obj) => obj.isaccepted === 1).length });
      });
  }

  handleChoiceSelect = (label, value) => {
    let sC = {
      id: Number(value),
      name: label
    };
    this.setState({ selectedChoice: sC, currentPage: 1 });
  };

  handleChoiceSelect2 = (label) => {
    this.setState({ selectedChoice2: label, currentPage: 1 });
  };


  handleSearch = query => {
    this.setState({ searchQuery: query, currentPage: 1 }); //selectedChoice: null,
  };


  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      selectedChoice,
      selectedChoice2,
      searchQuery,
      myData: allData,
      nAccepted
    } = this.state;

    let filtered = allData;
    if (this.props.user.role === "Affiliato") {
      if (selectedChoice && selectedChoice.id) filtered = filtered.filter(m => m.ana_quotationtype_id === selectedChoice.id);
    }
    else {
      if (selectedChoice && selectedChoice.id) filtered = filtered.filter(m => m.ana_user_id === selectedChoice.id);
    }

    if (selectedChoice2 && selectedChoice2.id) filtered = filtered.filter(m => m.isaccepted === Number(selectedChoice2.id));
    if (searchQuery) {
      filtered = filtered.filter(m =>
        (m.owner && m.owner.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (m.accountholder && m.accountholder.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (m.type && m.type.toLowerCase().includes(searchQuery.toLowerCase())));
    }
    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    const myData = paginate(sorted, currentPage, pageSize);
    return { totalCount: filtered.length, data: myData, nAcc: filtered.filter(m => m.isaccepted === 1).length };
  };

  render() {
    const { length: count } = this.state.myData;
    const {
      pageSize,
      currentPage,
      sortColumn,
      pagina,
      searchQuery,
      nAccepted,
      selectedChoice2
    } = this.state;

    if (count === 0) return (<React.Fragment>
      <div className="container-fluid">
        <div className="row m-4">
          <div className="col">
            <h3>{pagina}</h3>
            <div className="text-center">
              <img className="img-fluid" src={spinner} alt="loading..." />
            </div>
          </div>
          <div className="float-right pr-2">
            <Link className="btn btn-outline-success" to="/quotations/-1">
              {standardMessage.CMD_NEW}
            </Link>
          </div>
          <div className="float-right">
            {this.renderButtonLink(standardMessage.CMD_BACK, "/welcome")}
          </div>
        </div>
      </div>
    </React.Fragment>)

    const { totalCount, data: myData, nAcc } = this.getPagedData();

    return (
      <div className="container-fluid">
        <ToastContainer autoClose={config.autoCloseToast} />
        <div className="row m-4">
          <div className="col">
            <h3>{pagina}</h3>
          </div>
          <div className="float-right pr-2">
            <Link className="btn btn-outline-success" to="/quotations/-1">
              {standardMessage.CMD_NEW}
            </Link>
          </div>
          <div className="float-right">
            {this.renderButtonLink(standardMessage.CMD_BACK, "/welcome")}
          </div>
        </div>
        <div className="row">
          <div className="d-none d-lg-block col-sm-2">
            <SelectGroup
              items={this.state.choices}
              selectedItem={this.state.selectedChoice}
              onItemSelect={this.handleChoiceSelect}
            />
            <div style={{ paddingTop: "50px" }}>Per tipologia</div>
            <ListGroup
              items={this.state.quotationchoices}
              selectedItem={this.state.selectedChoice2}
              onItemSelect={this.handleChoiceSelect2}
            />
          </div>
          <div className="col-sm-10">
            <HowManyQuotations totalCount={totalCount} selectedChoice2={selectedChoice2} nAccepted={nAcc} />
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
            <QuotationlistTable
              myData={myData}
              sortColumn={sortColumn}
              onDelete={this.handleDelete}
              onDown={this.handleDown}
              onAccept={this.handleAccept}
              onSort={this.handleSort}
            />
            <Pagination
              key={totalCount}
              itemsCount={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

export { QuotationListPage };
