import React from "react";
import LoginAttemptlistTable from "../../../../_components/LoginattemptlistTable";
import ListGroup from "../../../../_components/common/listGroup";
import Pagination from "../../../../_components/common/pagination";
import SearchBox from "../../../../_components/common/searchBox";
import Form from "../../../../_components/common/form";
import { paginate } from "../../../../utils/paginate";
import { cicassService, auth } from "../../../../_services";
import { config } from "../../../../_helpers";
import { ToastContainer, toast } from "react-toastify";
import spinner from "../../../../images/form-preloader.gif";
import _ from "lodash";
import { standardMessage } from "../../../../_constants/";

const apiUrl = "loginattempts";
const apiUrlDel = "loginattempts";

class LoginAttemptPage extends Form {
  state = {
    myData: [], //cicassService.getAll(apiUrl)
    choices: [],
    pageSize: config.pageSize,
    currentPage: 1,
    searchQuery: "",
    selectedChoice: null,
    sortColumn: { path: "title", order: "asc" },
    pagina: "Tentativi di Login al Portale"
  };

  async componentDidMount() {
    const isExpired = auth.checkExpired();
    const { data: myData } = await cicassService.getAll(apiUrl);
    const choices = [
      { id: "", name: "Totali" },
      { id: "1", name: "Riusciti" },
      { id: "0", name: "Falliti" }
    ];

    //const mydata = this.state.myData.filter(p);
    this.setState({ myData, choices });
  }

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleDelete = async singleElem => {
    const originalData = this.state.myData;
    const myData = this.state.myData.filter(p => p.id !== singleElem.id);
    this.setState({ myData });
    //console.log(myData);
    try {
      // console.log("nel try ci entro");
      await cicassService.deleteOp(apiUrlDel, singleElem.id);
      toast.success("Riga eliminata.");
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("Non è stato possibile eliminare la riga.");
      this.setState({ myData: originalData });
    }
  };

  handleChoiceSelect = choice => {
    this.setState({ selectedChoice: choice, searchQuery: "", currentPage: 1 });
  };

  handleSearch = query => {
    this.setState({ searchQuery: query, currentPage: 1 }); //selectedChoice: null,
  };

  handleEdit = singleElem => {
    // await cicassService.deleteop("loginattempts", singleElem.id);
    // const myData = this.state.myData.filter(p => p.id !== singleElem.id);
    // this.setState({ myData });
    //console.log("Modifica", singleElem.id);
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      selectedChoice,
      searchQuery,
      myData: allData
    } = this.state;

    let filtered = allData;
    if (searchQuery) {
      if (selectedChoice && selectedChoice.id) {
        filtered = allData.filter(
          m =>
            m.succeeded === parseInt(selectedChoice.id) &&
            m.email.toLowerCase().includes(searchQuery.toLowerCase())
        );
      } else {
        filtered = allData.filter(m =>
          m.email.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
    } else {
      if (selectedChoice && selectedChoice.id)
        filtered = allData.filter(
          m => m.succeeded === parseInt(selectedChoice.id)
        ); //allData.filter(m => m.esito === selectedChoice.id);
    }

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const myData = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: myData };
  };

  render() {
    const { length: count } = this.state.myData;
    const {
      pageSize,
      currentPage,
      sortColumn,
      pagina,
      searchQuery
    } = this.state;

    if (count === 0) return (<React.Fragment>
      <div className="container-fluid">
        <div className="text-center">
          <img className="img-fluid" src={spinner} alt="loading..." />
        </div>
      </div>
    </React.Fragment>)

    const { totalCount, data: myData } = this.getPagedData();

    return (
      <div className="container-fluid">
        <ToastContainer autoClose={config.autoCloseToast} />
        <div className="row m-4">
          <div className="col">
            <h3>{pagina}</h3>
          </div>
          <div className="float-right pr-2">
            {this.renderButtonLink(standardMessage.CMD_BACK, "/welcome")}
          </div>
        </div>
        <div className="row">
          <div className="col-1">
            <ListGroup
              items={this.state.choices}
              selectedItem={this.state.selectedChoice}
              onItemSelect={this.handleChoiceSelect}
            />
          </div>
          <div className="col">
            {totalCount === 0 && (
              <p>
                Non sono stati trovati tentativi di login con queste condizioni
                di ricerca.
              </p>
            )}
            {totalCount === 1 && <p>E' stato trovato un tentativo di login.</p>}
            {totalCount > 1 && (
              <p>Sono stati effettuati {totalCount} tentativi di login.</p>
            )}

            <SearchBox value={searchQuery} onChange={this.handleSearch} />
            <LoginAttemptlistTable
              myData={myData}
              sortColumn={sortColumn}
              onDelete={this.handleDelete}
              onEdit={this.handleEdit}
              onSort={this.handleSort}
            />
            <Pagination
              key={totalCount}
              itemsCount={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

export { LoginAttemptPage };
