import React from "react";
import Joi from "joi-browser";
import Form from "../../../_components/common/form";
import Modal from "../../../_components/common/modal";
import { cicassService, auth } from "../../../_services";
import { config, history } from "../../../_helpers";
import { ToastContainer, toast } from "react-toastify";
import { standardMessage } from "../../../_constants/";
import Moment from 'moment';

const apiUrl = "ana_agency";
//const apiUrlSubsription = "ana_subscription";

class AgencyDetailPage extends Form {
  _isMounted = false;
  state = {
    data: {
      id: -1,
      name: "",
      piva: "",
      cf: "",
      address: "",
      city: "",
      postcode: "",
      province: "",
      state: "",
      email: "",
      pec: "",
      website: "",
      reference: "",
      ana_subscription_id: 1,
      refillValue: 0,
      ana_subscription_date: new Date()
    },
    isDefault: 0,
    isHidden: 0,
    viewOrder: 0,
    errors: {},
    disableInput: false,
    loadingSave: false,
    loadingDelete: false,
    disableButton: false,
    saveMessage: standardMessage.CMD_NEW,
    modalQuestion: "",
    anaSubscriptions: [],
    didMountCompleted: false
  };

  schema = {
    id: Joi.number(),
    name: Joi.string().required().max(50).label("Nome"),
    piva: Joi.string().empty("").max(11).label("P.IVA"),
    cf: Joi.string().empty("").max(16).label("Codice Fiscale"),
    address: Joi.string().empty("").max(50).label("Indirizzo"),
    city: Joi.string().empty("").max(50).label("Città"),
    postcode: Joi.string().empty("").min(5).max(5).label("CAP"),
    province: Joi.string().empty("").max(2).label("Provincia"),
    state: Joi.string().empty("").max(50).label("Stato"),
    email: Joi.string().empty("").email().max(100).label("Email"),
    pec: Joi.string().empty("").email().max(100).label("PEC"),
    website: Joi.string().empty("").max(255).label("Sito Web"),
    reference: Joi.string().empty("").max(50).label("Referente"),
    ana_subscription_id: Joi.number().required().label("Abbonamento"),
    refillValue: Joi.number().label("Refill (mese corrente)"),
    ana_subscription_date: Joi.date().label("Data inizio Contratto")
  };

  async componentDidMount() {
    this._isMounted = true;
    const isExpired = auth.checkExpired();
    let { data, disableInput } = this.state;
    const dataId = this.props.match.params.id;
    //const { data: anaSubscriptions } = await cicassService.getAll(apiUrlSubsription);
    let saveMessage = standardMessage.CMD_NEW;
    if (Number(dataId) != -1) {
      let { data: myData } = await cicassService.getById(apiUrl, dataId);
      data = this.mapToViewModel(myData);
      disableInput = true;
      saveMessage = standardMessage.CMD_EDIT;
    }
    if (this._isMounted) {
      if (data.id === 0) {
        history.replace("/not-found");
      }
      //  rompe la validazione se li lascio interni al campo data e non li voglio validare (risolvere in un secondo momento)
      //this.setState({ anaSubscriptions, data: this.mapToViewModel(data), isDefault: data.isDefault, isHidden: data.isHidden, viewOrder: data.viewOrder, saveMessage, disableInput, didMountCompleted: true });
      this.setState({ data: this.mapToViewModel(data), isDefault: data.isDefault, isHidden: data.isHidden, viewOrder: data.viewOrder, saveMessage, disableInput, didMountCompleted: true });
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  mapToViewModel(data) {
    // devo mappare i miei dati
    return {
      id: data.id,
      name: data.name,
      piva: data.piva,
      cf: data.cf,
      address: data.address,
      city: data.city,
      postcode: data.postcode,
      province: data.province,
      state: data.state,
      email: data.email,
      pec: data.pec,
      website: data.website,
      reference: data.reference,
      ana_subscription_id: data.ana_subscription_id,
      refillValue: (data.refill_value == null ? 0 : data.refill_value),
      ana_subscription_date: (data.ana_subscription_date != null ? new Date(Moment(data.ana_subscription_date).format("YYYY/MM/DD")) : null)
    };
  }

  handleModalMessage = idBtn => {
    switch (idBtn) {
      case "d": {
        this.setState({ modalQuestion: standardMessage.MODAL_QUESTION_DELETE });
        break;
      }
      case "s": {
        this.setState({ modalQuestion: standardMessage.MODAL_QUESTION_SAVE });
        break;
      }
      default:
        break;
    }
  };

  handleModalConfirm = () => {
    switch (this.state.modalQuestion) {
      case standardMessage.MODAL_QUESTION_DELETE: {
        this.handleDelete();
        break;
      }
      case standardMessage.MODAL_QUESTION_SAVE: {
        this.doSubmit();
        break;
      }
      default:
        break;
    }
  };

  handleDelete = async () => {
    this.setState({ loadingDelete: true, disableButton: true });
    try {
      await cicassService.deleteOp(apiUrl, this.state.data.id);
      toast.success(standardMessage.CMD_DELETE_OK);
      this.setState({ loadingDelete: false });
      window.location = "/agencies";
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error(standardMessage.CMD_DELETE_KO);
      this.setState({ loadingDelete: false });
    }
    this.setState({ disableButton: false });
  };

  doSubmit = async () => {
    this.setState({ loadingSave: true, disableButton: true });
    // SALVO in ANA_LOGIN
    let { data, isDefault, isHidden, viewOrder } = this.state;
    const dataToSave = {
      id: data.id,
      name: data.name,
      piva: data.piva,
      cf: data.cf,
      address: data.address,
      city: data.city,
      postcode: data.postcode,
      province: data.province,
      state: data.state,
      email: data.email,
      pec: data.pec,
      website: data.website,
      reference: data.reference,
      isDefault: isDefault,
      isHidden: isHidden,
      viewOrder: viewOrder,
      ana_subscription_id: Number(data.ana_subscription_id),
      refill_value: Number(data.refillValue == null ? 0 : data.refillValue),
      ana_subscription_date: Moment(data.ana_subscription_date).add(2, 'hours')
    };
    //console.log(dataToSave);
    const dbAna = await cicassService.saveOp(apiUrl, dataToSave);
    toast.success(standardMessage.CMD_SAVE_OK);
    //this.props.history.push("/users");
    this.setState({ loadingSave: false, disableButton: false });
    window.location = "/agencies";
  };

  render() {
    const { loadingSave, loadingDelete, saveMessage, disableButton, modalQuestion, anaSubscriptions, data, didMountCompleted } = this.state;
    const dataId = this.props.match.params.id;
    const refillLink = "/subrefillagency/" + dataId;
    return (

      <div className="container-fluid">
        <ToastContainer autoClose={config.autoCloseToast} />
        <div className="row m-4">
          <div className="col">
            <h1>Agenzia</h1>
          </div>
          <div className="float-right">
            {this.renderButtonLink(standardMessage.CMD_BACK, "/agencies")}
          </div>
        </div>
        <div>
          {didMountCompleted && (
            <form onSubmit={this.handleSubmit}>
              <div className="row m-2">
                <div className={config.bstrp3col}>{this.renderInput("name", "Agenzia", "Nome Agenzia", "text", this.state.disableInput)}</div>
                <div className={config.bstrp3col}>{this.renderInput("piva", "P.IVA", "P.IVA")}</div>
                <div className={config.bstrp3col}>{this.renderInput("cf", "Codice Fiscale", "Codice Fiscale")}</div>
                <div className={config.bstrp3col}>{this.renderInput("address", "Indirizzo", "Indirizzo")}</div>
                <div className={config.bstrp3col}>{this.renderInput("city", "Città", "Città")}</div>
                <div className={config.bstrp3col}>{this.renderInput("postcode", "CAP", "CAP", "decimal", false, "[0-9]*", "number")}</div>
                <div className={config.bstrp3col}>{this.renderInput("province", "Provincia", "Provincia")}</div>
                <div className={config.bstrp3col}>{this.renderInput("state", "Stato", "Stato")}</div>
                <div className={config.bstrp3col}>{this.renderInput("email", "Email", "Email", "email")}</div>
                <div className={config.bstrp3col}>{this.renderInput("pec", "PEC", "PEC", "email")}</div>
                <div className={config.bstrp3col}>{this.renderInput("website", "Sito WEB", "Sito Web")}</div>
                <div className={config.bstrp3col}>{this.renderInput("reference", "Referente", "Referente")}</div>
                {/* {anaSubscriptions != null && (
                  <React.Fragment>
                    <div className={config.bstrp3col}>{this.renderSelect("ana_subscription_id", "Contratto (incarichi)", anaSubscriptions)}</div>
                    <div className={config.bstrp6col}>{this.renderDateTime("ana_subscription_date", "Inizio Contratto", "Data Inizio Contratto", "it", "dd/MM/yyyy")}</div>
                    <div className={config.bstrp6col}>{this.renderInput("refillValue", "Refill (mese attuale)", "Refill")}</div>
                    <div className={config.bstrp6col}>{this.renderButtonLinkBottom(standardMessage.CMD_HISTORY, refillLink, "btn btn-outline-dark")}</div>
                  </React.Fragment>
                )} */}
              </div>
              {this.renderButtonModal(saveMessage, "modalOp", true, loadingSave, disableButton, () => { return this.handleModalMessage("s"); }, "btn btn-outline-success ml-4 mr-4 mb-4")}
              {dataId > -1 &&
                this.renderButtonModal(standardMessage.CMD_DELETE, "modalOp", true, loadingDelete, disableButton, () => { return this.handleModalMessage("d"); }, "btn btn-outline-danger mb-4")}
            </form>
          )}
        </div>
        <Modal modalId="modalOp" modalTitle={standardMessage.MODAL_TITLE_ATTENTION} modalBody={modalQuestion} onModalConfirm={this.handleModalConfirm} />
      </div>

    );
  }
}
export { AgencyDetailPage };
