import React from "react";

const InputCheckBox = ({
  id,
  name,
  label,
  disabled,
  // onfocus,
  error,
  link,
  linkUrl,
  linkTarget,
  ...rest
}) => {
  return (
    <div className="form-group form-check">
      <input
        {...rest}
        id={id}
        name={name}
        className="form-check-input"
        disabled={disabled}
        type="checkbox"
      //autoFocus={onfocus}
      />
      <label htmlFor={id} className="form-check-label">{label} {(link != "") && <a href={linkUrl} target={linkTarget}>{link}</a>}
      </label>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default InputCheckBox;
